import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icons } from 'src/assets';
import AuthBackground from 'src/components/pages/Auth/partials/AuthBackground';
import AuthContainerCard from 'src/components/pages/Auth/partials/AuthContainerCard';
import AuthError from 'src/components/pages/Auth/partials/AuthError';
import AuthFooter from 'src/components/pages/Auth/partials/AuthFooter';
import AuthInputCard from 'src/components/pages/Auth/partials/AuthInputCard';
import AuthLogo from 'src/components/pages/Auth/partials/AuthLogo';
import AuthSubmitButton from 'src/components/pages/Auth/partials/AuthSubmitButton';
import AuthTextInput from 'src/components/pages/Auth/partials/AuthTextInput';
import AuthTitle from 'src/components/pages/Auth/partials/AuthTitle';
import { authAction } from 'src/redux/auth/authReducer';
import { selectEmailInput, selectNameInput } from 'src/redux/selectors';
import { getSignupTokenCompanyStrapi } from 'src/services/strapi/strapiService';
import { createAppError } from 'src/types/appError';
import { Company } from 'src/types/auth';
import { useNavigate } from 'react-router-dom';

const SignupPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [company, setCompany] = useState<Company | null>(null);
  const email = useSelector(selectEmailInput);
  const name = useSelector(selectNameInput);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const signupToken = useMemo(() => {
    return new URLSearchParams(location.search).get('token') || '';
  }, [location]);

  useEffect(() => {
    getSignupTokenCompanyStrapi(signupToken)
      .then((company) => setCompany(company))
      .catch(() => {});
  }, [signupToken]);

  const signupClick = useCallback(() => {
    if (typeof signupToken !== 'string' || signupToken.length === 0) {
      dispatch(
        authAction.registerFailed(createAppError('invalid-signup-token', 'No signup token found in URL.')),
      );
      return;
    } else {
      const errors: string[] = [];
      if (name.length === 0) {
        errors.push('Name is required.');
      } else if (name.length < 2) {
        errors.push('Name must be at least two characters long.');
      }

      if (email.length === 0) {
        errors.push('Email is required.');
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        errors.push('Invalid email format.');
      }

      if (password.length < 8) {
        errors.push('Password must be at least 8 characters long.');
      } else if (password !== confirmPassword) {
        errors.push('Passwords do not match.');
      }

      if (errors.length > 0) {
        dispatch(authAction.registerFailed(createAppError('message', errors.join('\n'))));
        return;
      }
    }

    dispatch(authAction.register({ email, name, password, signupToken }));
  }, [signupToken, email, name, password, confirmPassword, dispatch]);

  const goToLogin = () => {
    navigate('/');
  };


  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthLogo />
        <AuthInputCard>
          <div className="flex justify-between items-start mb-2">
            <AuthTitle>Sign Up</AuthTitle>
            {company && (
              <p className="border-dark-border border py-0.5 px-2 text-white rounded-smaller ">
                {company.company}
              </p>
            )}
          </div>

          <AuthTextInput
            onChange={(e) => dispatch(authAction.setNameInput(e.target.value))}
            type="text"
            id="name"
            value={name}
            placeholder="Enter your name"
            icon={Icons.User}
          />

          <AuthTextInput
            onChange={(e) => dispatch(authAction.setEmailInput(e.target.value))}
            type="email"
            id="email"
            value={email}
            placeholder="Enter your email"
            icon={Icons.Mail}
          />

          <AuthTextInput
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            id="password"
            value={password}
            placeholder="Enter your password"
            icon={Icons.Arrow}
            iconClassName="transform -rotate-90"
          />

          <AuthTextInput
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            id="confirm-password"
            value={confirmPassword}
            placeholder="Confirm your password"
            icon={Icons.Arrow}
            iconClassName="transform -rotate-90"
          />
          <button
            onClick={goToLogin}
            className="-mt-2 mb-3 ml-1 self-start text-medium text-white hover:underline text-inter text-small li">
            Already have an account? Go to login
          </button>

          <AuthSubmitButton onClick={signupClick}>Sign up</AuthSubmitButton>
        </AuthInputCard>
        <AuthError />
        <AuthFooter />
      </AuthContainerCard>
    </AuthBackground>
  );
};

export default SignupPage;
