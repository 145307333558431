import React, { FC, PropsWithChildren } from 'react';

interface Props {
  onSubmit?: () => void;
  className?: string;
}

const AuthInputCard: FC<PropsWithChildren<Props>> = ({ children, onSubmit, className }) => {
  return (
    <div
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (onSubmit) {
            onSubmit();
          }
        }
      }}
      className={`flex relative flex-col items-stretch bg-dark-card p-5 pt-3 rounded-medium border-2 border-dark-border shadow-lg shadow-opacity-0 shadow-dark-border ${className ?? ''}`}>
      {children}
    </div>
  );
};

export default AuthInputCard;
