import React, { FC, PropsWithChildren } from 'react';

interface Props {
  selected?: boolean;
  select?: () => void;
}

const AuthTitle: FC<PropsWithChildren<Props>> = ({ children, select, selected = true }) => {
  const unselected =
    'font-light text-small text-opacity-50 hover:cursor-pointer border border-dark-border rounded-smaller px-3 pb-2 pt-2 hover:bg-dark-button hover:text-white';

  return (
    <h2
      onClick={select}
      className={`text-white font-inter tracking-md mb-4 ${selected ? 'text-xl' : unselected}`}>
      {children}
    </h2>
  );
};

export default AuthTitle;
