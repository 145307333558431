import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Icons } from 'src/assets';
import { authAction } from 'src/redux/auth/authReducer';
import { selectUser } from 'src/redux/selectors';
import { forgotPasswordStrapi } from 'src/services/strapi/strapiService';

const USER_PICTURE_SIZE = 38;
const ELEMENT_HEIGHT = 30;

const UserPictureCircle: FC = () => {
  return (
    <div
      style={{
        width: USER_PICTURE_SIZE,
        height: USER_PICTURE_SIZE,
        marginRight: (ELEMENT_HEIGHT - USER_PICTURE_SIZE) / 2,
      }}
      className="flex items-center justify-center">
      <Icons.UserPicture className="w-full h-full" />
    </div>
  );
};

const UserDropDown: FC = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const changePasswordClick = () => {
    if (!user) return;

    const forgotPasswordProcess = forgotPasswordStrapi(user.email);

    toast.promise(
      forgotPasswordProcess,
      {
        pending: 'Sending reset password email...',
        success: 'A link to reset your password has been sent to your email.',
        error: 'Failed to send reset password email. Please try again.',
      },
      {
        position: 'top-left',
      },
    );
  };

  const logOutClick = () => {
    dispatch(authAction.logout());
  };

  return (
    <div className="group relative">
      <div className="flex items-center">
        <div className="flex w-6 mr-3">
          <UserPictureCircle />
        </div>
        <Icons.Arrow className={`text-black h-3 w-3 transition-all group-hover:rotate-180 `} />
      </div>
      <div className="absolute top-0 right-0 pt-16 w-filter hidden group-hover:block ">
        <div
          className={` w-filter z-10 flex flex-col gap-6 justify-start shadow-qura overflow-hidden transition-all bg-qura-white rounded-md items-start px-4 py-5 `}>
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center">
              <p className="text-sm">{user?.name}</p>
              <p className=" bg-qura-btn-hover px-2 py-1 rounded-full text-xs">
                {user?.company.company}
              </p>
            </div>
            <p className="text-xs text-qura-secondary-text">{user?.email}</p>
          </div>

          <div className="flex flex-col gap-2 w-full text-xs">
            <button
              className="flex items-center text-qura-secondary-text hover:bg-qura-btn-hover px-2 py-[10px] rounded-md"
              onClick={changePasswordClick}>
              <div className="flex items-center mr-3 ">
                <Icons.Mail className="w-4 h-4" />
              </div>
              <p>Change Password</p>
            </button>

            <div className="h-[0.5px] w-full bg-qura-ternary-text" />

            <button
              className="flex items-center hover:bg-qura-btn-hover  px-2 py-[10px] rounded-md"
              onClick={logOutClick}>
              <div className="flex items-center mr-3">
                <Icons.Arrow className="w-3 h-3 rotate-90 text-qura-red" />
              </div>
              <p className="font-medium text-qura-red">Log Out</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDropDown;
