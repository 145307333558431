import React, { FC } from 'react';
import { AppError } from 'src/types/appError';

type Props = {
  className?: string;
  error: AppError | null;
};

const getErrorText = (error: AppError) => {
  switch (error.type) {
    case 'unimportant':
      return '';
    case 'invalid-credentials':
      return 'Invalid Credentials';
    case 'network':
      return 'You are offline.';
    case 'invalid-signup-token':
      return 'Your signup link is invalid.';
    case 'message':
      return error.message;
    case 'email-not-confirmed':
      return 'Email not confirmed.';
    case 'email-already-used':
      return 'Email has already been used with another account.';
    case 'no-results':
      return 'No results found.';
    case 'load-resources-failed':
      return 'Our servers are currently down.\nPlease try again later.';
    case 'unknown':
      return 'An unknown has error occurred.';
    case 'identical-request':
      return '';
  }
};

const ErrorMessage: FC<Props> = ({ error, className }) => {
  if (
    error === null ||
    error.type === 'unimportant' ||
    error.type === 'email-not-confirmed' ||
    error.type === 'identical-request'
  ) {
    return null;
  }

  return getErrorText(error)
    .split('\n')
    .map((m) => (
      <p key={m} className={`font-inter ${className}`}>
        {m}
      </p>
    ));
};

export default ErrorMessage;
