export const appErrorTypes = [
  'network',
  'unknown',
  'invalid-credentials',
  'unimportant',
  'identical-request',
  'invalid-signup-token',
  'message',
  'email-already-used',
  'email-not-confirmed',
  'no-results',
  'load-resources-failed',
] as const;

export type AppErrorType = (typeof appErrorTypes)[number];

export interface AppError {
  type: AppErrorType;
  message: string;
}

export const createAppError = (type: AppErrorType, message: string) => {
  return {
    type,
    message,
  };
};
