import React, { FC } from 'react';
import { Icons } from 'src/assets';

type Props = {
  className?: string;
};

const LoadingCover: FC<Props> = ({ className }) => {
  return (
    <div
      className={`z-50 w-screen h-screen flex justify-center items-center bg-white ${className}`}>
      <div className="overflow-hidden">
        <Icons.LogoText />
      </div>
    </div>
  );
};

export default LoadingCover;
