import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from 'src/assets';
import AuthBackground from 'src/components/pages/Auth/partials/AuthBackground';
import AuthContainerCard from 'src/components/pages/Auth/partials/AuthContainerCard';
import AuthError from 'src/components/pages/Auth/partials/AuthError';
import AuthFooter from 'src/components/pages/Auth/partials/AuthFooter';
import AuthInputCard from 'src/components/pages/Auth/partials/AuthInputCard';
import AuthLogo from 'src/components/pages/Auth/partials/AuthLogo';
import AuthSubmitButton from 'src/components/pages/Auth/partials/AuthSubmitButton';
import AuthTextInput from 'src/components/pages/Auth/partials/AuthTextInput';
import AuthTitle from 'src/components/pages/Auth/partials/AuthTitle';
import { authAction } from 'src/redux/auth/authReducer';
import { selectEmailInput } from 'src/redux/selectors';
import { AppError } from 'src/types/appError';

const emailConfirmationMessages = {
  confirmed: 'Your email has been confirmed.\nYou can now log in.',
  failed: 'Your email confirmation link is invalid.\nPlease try to log in again.',
  'already-confirmed': 'Your email has already been confirmed.\nYou can log in.',
};
type EmailConfirmationState = keyof typeof emailConfirmationMessages;

const LoginPage: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailConfirmation = useMemo<EmailConfirmationState | null>(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailConfirmation = searchParams.get('email-confirmation');

    if (emailConfirmation && emailConfirmation in emailConfirmationMessages) {
      return emailConfirmation as EmailConfirmationState;
    } else {
      return null;
    }
  }, [location.search]);

  useEffect(() => {
    if (emailConfirmation) {
      const confirmedMessage: AppError = {
        type: 'message',
        message: emailConfirmationMessages[emailConfirmation],
      };

      dispatch(authAction.setError(confirmedMessage));
    }
  }, [emailConfirmation]);

  const email = useSelector(selectEmailInput);
  const [password, setPassword] = useState<string>('');

  const loginClick = () => {
    navigate('/auth');
    dispatch(authAction.setError(null));
    dispatch(authAction.login({ email, password }));
  };

  const forgotPasswordClick = () => {
    navigate('/auth/forgot-password');
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthLogo />
        <AuthInputCard onSubmit={loginClick}>
          <AuthTitle>Log In</AuthTitle>
          <AuthTextInput
            onChange={(e) => dispatch(authAction.setEmailInput(e.target.value))}
            type="email"
            id="email"
            value={email}
            placeholder="Enter your email"
            icon={Icons.Mail}
          />
          <AuthTextInput
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            id="password"
            value={password}
            placeholder="Enter your password"
            icon={Icons.Arrow}
            iconClassName="transform -rotate-90"
          />
          <button
            onClick={forgotPasswordClick}
            className="-mt-2 mb-3 ml-1 self-start text-medium text-white hover:underline text-inter text-small li">
            Forgot password?
          </button>
          <AuthSubmitButton onClick={loginClick}>Log in</AuthSubmitButton>
        </AuthInputCard>
        <AuthError />
        <AuthFooter />
      </AuthContainerCard>
    </AuthBackground>
  );
};

export default LoginPage;
