import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ErrorMessage from 'src/components/common/ErrorMessage/ErrorMessage';
import Layout from 'src/components/pages/Search/partials/Layout/Layout';
import LoadingGradient from 'src/components/pages/Search/partials/LoadingGradient/LoadingGradient';
import PDFReader from 'src/components/pages/Search/partials/PDFReader/PDFReader';
import ResultChunk from 'src/components/pages/Search/partials/ResultChunk/ResultChunk';
import { searchAction } from 'src/redux/search/searchReducer';
import {
  selectCurrentSearch,
  selectDocumentCache,
  selectSearchError,
  selectSearchIsLoading,
} from 'src/redux/selectors';
import { CHUNK_COUNT_LIMIT, getSearchedDocumentMetaData } from 'src/services/api/apiService';
import { ApiDocumentMeta, ApiResultChunk } from 'src/types/api';

const ResultChunks = ({
  onClick,
  chunks,
}: {
  onClick: (value: { number: number; id: number }) => void;
  chunks?: ApiResultChunk[];
}) => {
  const searchIsLoading = useSelector(selectSearchIsLoading);

  if (!chunks) return;

  const chunkScores = chunks.map((chunk) => chunk.score);
  const maxChunkScore = Math.max(...chunkScores);
  const minChunkScore = Math.min(...chunkScores);
  const chunkScoreDelta = maxChunkScore - minChunkScore;

  const sortedChunks = chunks.map((chunk) => chunk).sort((a, b) => a.pageNumber - b.pageNumber);

  return (
    <div className=" h-full bg-qura-white shadow-qura grid w-filter rounded-md grid-cols-1 grid-rows-[3.5rem_1fr] pointer-events-auto">
      <div className="border-b border-gray-200 text-sm text-center py-5 ">
        <p>Search results in document</p>
      </div>
      <div className=" py-5 flex flex-col gap-4 overflow-y-auto  items-center">
        {!searchIsLoading
          ? sortedChunks.map((chunk) => {
              return (
                <div
                  className=" flex flex-col items-center w-36 justify-between cursor-pointer flex-shrink-0 px-1 gap-2"
                  onClick={() => onClick({ number: chunk.pageNumber, id: Math.random() })}
                  key={chunk.pageNumber}>
                  <ResultChunk
                    pageNumber={chunk.pageNumber + 1}
                    pdfExcerpt={chunk.pdfExcerpt}
                    className="rounded shadow-qura p-1"
                    renderTextLayer={false}
                    score={(chunk.score - minChunkScore) / chunkScoreDelta}
                    relevanceLevel={chunk.relevanceLevel}
                    width={136}
                  />
                </div>
              );
            })
          : Array(CHUNK_COUNT_LIMIT)
              .fill(undefined)
              .map((_, index) => <LoadingGradient className="w-36 h-36" key={index} />)}
      </div>
    </div>
  );
};

const SearchDocumentPage: FC = () => {
  const [searchParams] = useSearchParams();
  const paramsPageNumber = searchParams.get('page');
  const [pageNumber, setPageNumber] = useState({
    number: parseInt(paramsPageNumber || '0'),
    id: Math.random(),
  });
  const { searchId, documentId } = useParams();
  const originalSearchId = useRef('');
  const extension = parseInt(searchParams.get('extension') || '0');
  const search = useSelector(selectCurrentSearch);
  const documents = useSelector(selectDocumentCache);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const document = documentId ? documents[documentId] : undefined;
  const searchError = useSelector(selectSearchError);
  const [documentMeta, setDocumentMeta] = useState<ApiDocumentMeta | undefined>(undefined);
  const [metaLoading, setMetaLoading] = useState(false);
  const [metaError, setMetaError] = useState(false);
  const displayError = searchError ? searchError.type !== 'identical-request' && searchError.type !== 'unimportant' : false;

  const firstChunkPageNumber = useMemo(
    () => document?.resultChunks[0]?.pageNumber,
    [document?.resultChunks],
  );

  useEffect(() => {
    if (searchId) originalSearchId.current = searchId;
  }, []);

  useEffect(() => {
    if (searchId && searchId !== search?.searchId) {
      dispatch(
        searchAction.loadSearch({
          searchId: searchId,
          firstPageIndex: 0,
          nextPageIndex: extension,
          resultUrl: '',
        }),
      );
    }
  }, [searchId, extension]);

  useEffect(() => {
    dispatch(searchAction.setDocumentFilter([documentId ?? '']));
    return () => {
      dispatch(searchAction.setDocumentFilter([]));
    };
  }, [documentId]);

  useEffect(() => {
    if (search?.searchId && search.searchId !== searchId) {
      navigate(`/search/${search.searchId}/${documentId}`);
    }
  }, [search]);

  useEffect(() => {
    if (!paramsPageNumber) {
      setPageNumber({
        number: firstChunkPageNumber || 0,
        id: Math.random(),
      });
    }
  }, [firstChunkPageNumber, paramsPageNumber]);

  useEffect(() => {
    const fetchMeta = async () => {
      setMetaLoading(true);
      if (searchId && documentId) {
        try {
          setDocumentMeta(await getSearchedDocumentMetaData(searchId, documentId));
        } catch (error) {
          console.error('Error fetching document meta data');
          console.error(error);
          setMetaError(true);
        }
      }
      setMetaLoading(false);
    };

    fetchMeta();
  }, [documentId, searchId]);

  return (
    <Layout
      showBackButton
      originalSearchId={originalSearchId.current}
      contentClassName="w-[min(740px,80%)]"
      leftContent={() => <ResultChunks onClick={setPageNumber} chunks={document?.resultChunks} />}>
      {!displayError && !metaError && (
        <PDFReader
          documentMeta={documentMeta}
          documentMetaLoading={metaLoading}
          pageNumber={pageNumber}
          legalId={document?.legalId}
        />
      )}
      {displayError && (
        <div className="flex flex-col gap-2 items-start">
          <ErrorMessage error={searchError} className="text-xs" />
          <button
            className="bg-qura-white border border-qura-border text-qura-primary-text hover:bg-qura-btn-hover text-xs px-2 py-1.5 rounded"
            onClick={() => dispatch(searchAction.newSearch())}>
            Try again
          </button>
        </div>
      )}
      {metaError && !displayError && (
        <div className="flex flex-col gap-2 items-start">
          <ErrorMessage error={{ type: 'unknown', message: 'meta error' }} className="text-xs" />
          <button
            className="bg-qura-white border border-qura-border text-qura-primary-text hover:bg-qura-btn-hover text-xs px-2 py-1.5 rounded"
            onClick={() => window.location.reload()}>
            Try again
          </button>
        </div>
      )}
    </Layout>
  );
};

export default SearchDocumentPage;
