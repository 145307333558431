import { createSlice } from '@reduxjs/toolkit';
import { createAsyncActions } from 'src/redux/reduxHelpers';
import { ApiResources } from 'src/types/api';
import { AppError } from 'src/types/appError';

type State = {
  resources: ApiResources | null;
  tagIdLabelDict: Record<string, string>;
  isLoading: boolean;
  hasLoaded: boolean;
  error: AppError | null;
};

const initialState: State = {
  resources: null,
  tagIdLabelDict: {},
  isLoading: false,
  hasLoaded: false,
  error: null,
};

export const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    clear: (state) => {
      state.resources = null;
      state.isLoading = false;
      state.hasLoaded = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadResources, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loadResourcesSuccess, (state, { payload }) => {
        state.resources = payload;
        state.isLoading = false;
        state.hasLoaded = true;

        const tagIdLabelDict: Record<string, string> = {};
        const recursiveSetInDict = (tags: ApiResources['filters']['tags']) => {
          tags.forEach((tag) => {
            tagIdLabelDict[tag.id] = tag.label;
            if (tag.subtags) recursiveSetInDict(tag.subtags);
          });
        };

        recursiveSetInDict(payload.filters.tags);
        state.tagIdLabelDict = tagIdLabelDict;
      })
      .addCase(loadResourcesFailed, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

const [loadResources, loadResourcesSuccess, loadResourcesFailed] = createAsyncActions<
  void,
  ApiResources
>('resources/load');

export const resourceAction = {
  ...resourceSlice.actions,
  loadResources: loadResources,
  loadResourcesSuccess,
  loadResourcesFailed,
};

export default resourceSlice.reducer;
