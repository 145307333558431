import { AppError, AppErrorType } from 'src/types/appError';

import { validateRecord } from './validateRecord';

function catchAppError<T>(type: AppErrorType, promise: Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    promise.then(resolve).catch((error: unknown) => {
      const appError = validateRecord(error, 'apperror');

      reject({
        type,
        message: appError.message,
      } as AppError);
    });
  });
}

export default catchAppError;
