import { FC, PropsWithChildren } from 'react';

type Props = {
  onClick?: () => void;
  className?: string;
};

const ResultCard: FC<PropsWithChildren<Props>> = ({ onClick, children, className }) => {
  return (
    <div
      className={`flex flex-col px-7 py-4 rounded-medium bg-qura-white shadow-qura ${className} ${
        onClick ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        if (window.getSelection()?.toString().length === 0) onClick?.();
      }}>
      {children}
    </div>
  );
};

export default ResultCard;
