import { ReactComponent as Arrow } from './icons/arrow-icon.svg';
import { ReactComponent as ArrowLarge } from './icons/arrow-large.svg';
import { ReactComponent as ArrowTiltedUpRight } from './icons/arrow-tilted-up-right.svg';
import { ReactComponent as Book } from './icons/book-icon.svg';
import { ReactComponent as Calendar } from './icons/calendar-icon.svg';
import { ReactComponent as Chat } from './icons/chat-icon.svg';
import { ReactComponent as CheckMark } from './icons/checkmark.svg';
import { ReactComponent as Close } from './icons/close-icon.svg';
import { ReactComponent as Logo } from './icons/logo-icon.svg';
import { ReactComponent as LogoText } from './icons/logo-text.svg';
import { ReactComponent as Mail } from './icons/mail-icon.svg';
import { ReactComponent as NewSearch } from './icons/new-search-icon.svg';
import { ReactComponent as QuestionMark } from './icons/question-mark-icon.svg';
import { ReactComponent as Search } from './icons/search-icon.svg';
import { ReactComponent as User } from './icons/user-icon.svg';
import { ReactComponent as UserPicture } from './icons/user-icon2.svg';

export const Icons = {
  Logo,
  LogoText,
  Search,
  Chat,
  Arrow,
  Book,
  QuestionMark,
  Close,
  Mail,
  User,
  ArrowLarge,
  Calendar,
  ArrowTiltedUpRight,
  NewSearch,
  UserPicture,
  CheckMark,
};
