import { FC, MouseEventHandler, ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icons } from 'src/assets';
import { searchAction } from 'src/redux/search/searchReducer';
import { selectTagSelected } from 'src/redux/selectors';
import { ApiTag } from 'src/types/api';

type Props = {
  tag: ApiTag;
  tagPath: string[];
  renderSubTag?: (parentOption: ApiTag, option: ApiTag, index: number) => ReactNode;
};

const TagButton: FC<Props> = ({ tagPath, tag, renderSubTag: renderSuboption }) => {
  const dispatch = useDispatch();
  const [hideSuboptions, setHideSuboptions] = useState(false);
  const isSelected = useSelector(selectTagSelected(tagPath));

  const selectClick = () => {
    if (isSelected) {
      dispatch(
        searchAction.removeTagCombinationToFilter({
          tag: tag.id,
        }),
      );
    } else {
      dispatch(
        searchAction.addTagCombinationToFilter({
          tagPath,
        }),
      );
    }
  };

  const hideClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setHideSuboptions((hide) => !hide);
  };

  return (
    <>
      <div
        className={`flex first-letter items-center justify-left rounded-[6px] p-2 cursor-pointer hover:bg-qura-btn-hover ${
          isSelected ? 'bg-qura-btn-hover' : ''
        }`}
        onClick={selectClick}>
        <div className=" w-2 h-2 rounded-full border border-qura-primary-text flex justify-center items-center">
          {isSelected && <div className=" w-1 h-1  rounded-full bg-qura-primary-text" />}
        </div>

        <p className={`flex-1 pl-2  ${isSelected ? '' : 'text-qura-primary-text mr-1'}`}>
          {tag.label}
        </p>
        {tag.subtags &&
          renderSuboption &&
          (isSelected ? (
            <button className="text-right hover:underline" onClick={hideClick}>
              <Icons.Arrow className={`w-2 ${hideSuboptions ? ' ' : 'rotate-180'}`} />
            </button>
          ) : (
            <Icons.Arrow className="w-2" />
          ))}
      </div>
      {isSelected && tag.subtags && renderSuboption && !hideSuboptions && (
        <div className="flex">
          <div className="w-[1px] -mt-0.5 mb-3 ml-3 bg-qura-border" />
          <div className="flex flex-col flex-1 gap-1">
            {tag.subtags.map((fo, i) => renderSuboption(tag, fo, i))}
          </div>
        </div>
      )}
    </>
  );
};

export default TagButton;
