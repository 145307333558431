import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icons } from 'src/assets';
import { selectUser } from 'src/redux/selectors';
import store from 'src/redux/store';
import { sendFeedbackRequest } from 'src/services/api/apiService';

const LINE_HEIGHT = 30;
const MAX_HEIGHT = 30 * 6;

const Feedback: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showingMessage, setShowingMessage] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const messageTimeout = useRef<NodeJS.Timeout | null>(null);
  const user = useSelector(selectUser);

  const showMessage = (message: string, time: number) => {
    setMessage(message);
    setShowingMessage(true);
    if (messageTimeout.current) {
      clearTimeout(messageTimeout.current);
    }
    messageTimeout.current = setTimeout(() => {
      setShowingMessage(false);
    }, time);
  };

  const onSend = () => {
    if (!user) return;

    const feedback_metadata = {
      store: store.getState(),
      location: window.location,
      email: user.email,
      name: user.name,
      user_id: user.id,
    };

    setIsActive(false);
    showMessage('Sending...', 1000);
    sendFeedbackRequest(value, feedback_metadata)
      .then(() => {
        showMessage('Feedback sent', 1000);
        setValue('');
      })
      .catch(() => {
        showMessage('Failed to send feedback', 10000);
        setIsActive(true);
      });
  };

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    updateTextareaHeight();
  };

  useEffect(() => {
    updateTextareaHeight();
  }, [value]);

  const updateTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <>
      {isActive && (
        <div className="fixed z-0 pointer-events-auto inset-0" onClick={() => setIsActive(false)} />
      )}
      {isActive ? (
        <div className="fixed z-10 right-8 bottom-8 pointer-events-auto flex items-center flex-col">
          <div className="bg-white flex items-center border border-gray700 py-4 rounded-bigger w-[max(30vw,350px)] shadow-qura">
            <textarea
              className="flex-1 ml-6 font-inter text-medium text-black focus:border-primary focus:outline-none resize-none"
              disabled={!isActive}
              style={{
                lineHeight: `${LINE_HEIGHT}px`,
                height: value.length > 0 ? undefined : `${LINE_HEIGHT}px`,
              }}
              ref={textareaRef}
              value={value}
              onChange={onChangeTextarea}
              onKeyDown={handleKeyDown}
              placeholder="Type your feedback here..."
            />
            <button
              disabled={value.length === 0}
              className={`flex mx-3 items-center justify-center h-10 w-10 bg-card rounded-smaller ${
                value.length > 0 ? 'opacity-100' : 'opacity-40'
              }`}
              onClick={onSend}>
              <Icons.ArrowLarge className="h-4 w-4 text-gray-600" />
            </button>
          </div>
          {showingMessage && (
            <p className="font-inter font-regular mt-2 text-black text-sm">{message}</p>
          )}
        </div>
      ) : (
        <div className="fixed flex items-center z-10 right-8 bottom-8 pointer-events-auto">
          {
            <p
              className={`font-inter font-regular ${
                showingMessage ? 'opacity-100' : 'opacity-0'
              } transition-opacity duration-1000 mr-5 pointer-events-none`}>
              {message}
            </p>
          }
          <button
            onClick={() => setIsActive(true)}
            className="shadow-qura flex items-center justify-center h-14 w-14 bg-qura-white bg-opacity-90 rounded-smaller">
            <Icons.Chat className="h-8 w-8 text-black" />
          </button>
        </div>
      )}
    </>
  );
};

export default Feedback;
