import { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectEmailToConfirm, selectRawUser } from 'src/redux/selectors';
import { RootState } from 'src/redux/store';

const appropriateRouteSelector = (state: RootState, pathname: string): string | null => {
  const isOnAuth = pathname.startsWith('/auth');
  const isOnEmailConfirmation = pathname === '/auth/confirm-email';
  const isOnSearch = pathname === '/' || pathname.startsWith('/search');
  const user = selectRawUser(state);

  const emailToConfirm = selectEmailToConfirm(state);
  if (emailToConfirm) {
    return '/auth/confirm-email';
  } else if (isOnEmailConfirmation) {
    return '/auth';
  }

  if (!user) return null;
  if (user !== 'USER_NOT_FOUND') {
    if (isOnAuth) {
      return '/';
    }
  } else {
    if (isOnSearch) {
      return '/auth';
    }
  }

  return null;
};

const RedirectToAppropriateRoute: FC = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const pathHistoryRef = useRef<string[]>([pathname]);
  const shouldNavigateTo = useSelector((state: RootState) =>
    appropriateRouteSelector(state, pathname),
  );

  const searchParamsState = useRef<Record<string, string>>({});
  useEffect(() => {
    searchParamsState.current[pathname] = search;
  }, [search]);

  useEffect(() => {
    if (
      shouldNavigateTo !== null &&
      pathHistoryRef.current[pathHistoryRef.current.length - 1] !== shouldNavigateTo
    ) {
      pathHistoryRef.current.push(shouldNavigateTo);
      const savedSearchParams = searchParamsState.current[shouldNavigateTo] ?? '';
      navigate(shouldNavigateTo + savedSearchParams);
    }
  }, [shouldNavigateTo]);

  return null;
};

export default RedirectToAppropriateRoute;
