import { ApiTag } from 'src/types/api';

import { determineTagSelected } from './search/searchHelpers';
import { RootState } from './store';

export const selectRawUser = (state: RootState) => state.auth.user;
export const selectUser = (state: RootState) =>
  state.auth.user && state.auth?.user !== 'USER_NOT_FOUND' ? state.auth.user : null;
export const selectCurrentSearch = (state: RootState) => state.search.search;
export const selectNewSearchIsLoading = (state: RootState) =>
  state.search.areLoading.includes('new-search');
export const selectSearchError = (state: RootState) => state.search.error;

export const selectSearchParameters = (state: RootState) => state.search.parameters;

export const selectSearchQuery = (state: RootState) => state.search.parameters.query;

export const selectSearchResults = (state: RootState) => state.search.resultPages;

export const selectResourceHasLoaded = (state: RootState) => state.resources.hasLoaded;
export const selectUserIsLoggedIn = (state: RootState) =>
  state.auth.user !== null && state.auth.user !== 'USER_NOT_FOUND';

// we have to use the same empty array for comparison
// otherwise the selector will always return a new array
const NO_TAGS: ApiTag[] = [];
export const selectAvailableDocumentTags = (state: RootState) =>
  state.resources.resources?.filters.tags ?? NO_TAGS;

export const selectTagSelected = (tagPath: string[]) => (state: RootState) => {
  const parameters = selectSearchParameters(state);
  const tagSelection = parameters.filter?.tags ?? [];
  return determineTagSelected(tagPath, tagSelection);
};

export const selectAuthError = (state: RootState) => state.auth.error;

export const selectResourcesAreLoading = (state: RootState) => state.resources.isLoading;
export const selectAuthIsLoading = (state: RootState) => state.auth.areLoading.length > 0;

export const selectEmailInput = (state: RootState) => state.auth.input.email;
export const selectNameInput = (state: RootState) => state.auth.input.name;

export const selectEmailToConfirm = (state: RootState) => state.auth.emailToConfirm;
export const selectIsRefreshing = (state: RootState) => state.auth.areLoading.includes('refresh');
export const selectIsLoggingOut = (state: RootState) => state.auth.areLoading.includes('logout');
export const selectHasRefreshed = (state: RootState) => state.auth.hasRefreshed;
export const selectDocumentCache = (state: RootState) => state.search.documentCache;

export const selectAvailableDateRange = (state: RootState) =>
  state.resources.resources?.filters.dateRange;
export const selectFilterDateRange = (state: RootState) =>
  state.search.parameters.filter?.dateRange ?? null;

export const selectWaitingForPage = (state: RootState) => {
  const index = state.search.search?.nextPageIndex;

  if (index === undefined) {
    return false;
  }

  return (
    state.search.resultPages.length < index + 1 ||
    state.search.resultPages[index]?.status === 'PENDING' ||
    state.search.resultPages[index]?.status === 'PROCESSING'
  );
};
export const selectSearchIsLoading = (state: RootState) =>
  state.search.areLoading.length > 0 || selectWaitingForPage(state);
export const selectTagIdLabelDict = (state: RootState) => state.resources.tagIdLabelDict;

export const selectSubmittedParams = (state: RootState) => state.search.submittedParams;
export const selectSubmittedFilters = (state: RootState) => state.search.submittedParams?.filter;

export const selectFilter = (state: RootState) => state.search.parameters.filter;

export const selectSearchIsProcessingOrPendingInitially = (state: RootState) =>
  (state.search.resultPages[0]?.status === 'PROCESSING' ||
    state.search.resultPages[0]?.status === 'PENDING' ||
    state.search.resultPages.length === 0) &&
  !state.search.areLoading.includes('load-search') &&
  !(state.search.error && state.search.error.type === 'identical-request');
