import React, { FC } from 'react';

const AuthFooter: FC = () => {
  return (
    <p className="z-10 text-white text-tiny mt-8 mx-2 text-center">
      Qura uses AI to find the relevant laws, cases and regulations for any legal context. We strive
      to transform legal research.
    </p>
  );
};

export default AuthFooter;
