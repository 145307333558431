import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Icons } from 'src/assets';
import Feedback from 'src/components/pages/Search/partials/Feedback/Feedback';
import SearchBar from 'src/components/pages/Search/partials/SearchBar/SearchBar';
import UserDropDown from 'src/components/pages/Search/partials/UserDropDown/UserDropDown';
import { searchAction } from 'src/redux/search/searchReducer';
import { selectSearchQuery } from 'src/redux/selectors';

type TopBarProps = {
  showBackButton?: boolean;
  originalSearchId?: string;
};

const TopBar = forwardRef<HTMLHeadElement, TopBarProps>(
  ({ showBackButton, originalSearchId }, ref) => {
    const dispatch = useDispatch();
    const query = useSelector(selectSearchQuery);

    const onQueryChange = (query: string) => {
      dispatch(searchAction.setQuery({ query }));
    };

    const startSearch = () => {
      dispatch(searchAction.newSearch());
    };

    return (
      <header ref={ref} className="fixed top-0 z-10 ">
        <div className="flex items-end pt-6 bg-qura-bg">
          <div className="flex w-screen px-10 items-center gap-10 relative">
            <div className="flex flex-col">
              <Link to="/" onClick={() => dispatch(searchAction.clear())}>
                <Icons.LogoText className=" w-20" />
              </Link>
              {showBackButton && (
                <div className="self-start h-0">
                  <Link
                    className="absolute flex mt-[6px] text-black gap-1 items-center"
                    to={`/search/${originalSearchId}`}
                    relative="path"
                    onClick={() => dispatch(searchAction.clear())}>
                    <Icons.Arrow className="w-3 h-3 rotate-90 text-gray-600" />
                    <span className="text-sm text-gray-600">All Results</span>
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <SearchBar
                value={query}
                setValue={onQueryChange}
                onSearch={startSearch}
                disabled={false}
              />
            </div>
            <UserDropDown />
          </div>
        </div>
      </header>
    );
  },
);

const LeftSideBar = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    overlap: number;
    topBarHeight?: number;
    contentWidth: number;
  }
>(({ children, topBarHeight, overlap, contentWidth }, ref) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      className="fixed left-0 pr-8 pt-8 pb-8 z-10  pointer-events-none h-fit flex justify-end "
      style={{
        maxHeight: `calc(100vh - ${topBarHeight}px + 2rem)`,
        top: `calc(${topBarHeight}px - 2rem)`,
        width: `calc((100svw - ${contentWidth}px) / 2)`,
      }}>
      <div
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`transition-all flex-shrink-0 flex justify-end pointer-events-auto`}
        style={{
          width: 258,
          transform: overlap < 0 && isHover ? `translateX(${-overlap}px)` : 'none',
        }}>
        {children}
      </div>
    </div>
  );
});

const Layout = ({
  children,
  leftContent,
  showBackButton,
  contentClassName = 'w-4/5',
  originalSearchId,
}: {
  children: ReactNode;
  leftContent?: (props: { overlap: number }) => ReactNode;
  showBackButton?: boolean;
  originalSearchId?: string;
  contentClassName?: string;
}) => {
  const leftRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const topBarRef = useRef<HTMLHeadElement>(null);
  const query = useSelector(selectSearchQuery);

  const [leftOverLap, setLeftOverLap] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [topBarHeight, setTopBarHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleWindowResize = () => {
      if (leftRef.current === null || contentRef.current === null) return;

      setLeftOverLap(leftRef.current.offsetLeft);
      setContentWidth(contentRef.current.clientWidth);
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  });

  useEffect(() => {
    setTopBarHeight((topBarRef.current?.clientHeight || 0) + 32);
  }, [query]);

  return (
    <main
      id="main"
      className="flex flex-col items-center pb-8"
      style={{ paddingTop: topBarHeight }}>
      <TopBar ref={topBarRef} showBackButton={showBackButton} originalSearchId={originalSearchId} />
      <LeftSideBar
        topBarHeight={topBarHeight}
        contentWidth={contentWidth}
        overlap={leftOverLap}
        ref={leftRef}>
        {leftContent && leftContent({ overlap: leftOverLap })}
      </LeftSideBar>
      <div
        ref={contentRef}
        className={contentClassName}
        style={{ height: `calc(100vh - ${topBarHeight}px - 2rem)` }}>
        {children}
      </div>
      <Feedback />
      <ToastContainer className="pointer-events-auto" />
    </main>
  );
};

export default Layout;
