import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { createAsyncSagaHandle } from 'src/redux/reduxHelpers';
import { selectEmailInput } from 'src/redux/selectors';
import {
  loginStrapi,
  logoutStrapi,
  refreshStrapi,
  sendEmailConfirmationStrapi,
  signupStrapi,
} from 'src/services/strapi/strapiService';
import { AppError } from 'src/types/appError';
import { LoginCredentials, RegisterCredentials, User } from 'src/types/auth';
import catchAppError from 'src/utilities/catchAppError';

import { authAction } from './authReducer';

const handleLogin = createAsyncSagaHandle<LoginCredentials, User>(
  ({ email, password }) => loginStrapi(email, password),
  authAction.loginSuccess,
  authAction.loginFailed,
);

const handleRefresh = createAsyncSagaHandle<void, User>(
  () => catchAppError('unimportant', refreshStrapi()),
  authAction.refreshSuccess,
  authAction.refreshFailed,
);

const handleLogout = createAsyncSagaHandle<void, void>(
  () => logoutStrapi(),
  authAction.logoutSuccess,
  authAction.logoutFailed,
);

const handleRegister = createAsyncSagaHandle<RegisterCredentials, LoginCredentials>(
  ({ email, password, name, signupToken }) => signupStrapi(name, email, password, signupToken),
  authAction.registerSuccess,
  authAction.registerFailed,
);

const handleEmailConfirmationLink = createAsyncSagaHandle<string, string>(
  (email) => sendEmailConfirmationStrapi(email),
  authAction.emailConfirmationLinkSuccess,
  authAction.emailConfirmationLinkFailed,
);

function* handleLoginFailed(action: PayloadAction<AppError>) {
  if (action.payload.type === 'email-not-confirmed') {
    const email: string = yield select(selectEmailInput);
    yield put(authAction.emailConfirmationLink(email));
  }
}

function* handleRegisterSuccess({ payload }: PayloadAction<LoginCredentials>) {
  yield put(authAction.login(payload));
}

function* authSaga() {
  yield all([
    takeLatest(authAction.registerSuccess, handleRegisterSuccess),
    takeLatest(authAction.loginFailed, handleLoginFailed),
    takeLatest(authAction.login, handleLogin),
    takeLatest(authAction.refresh, handleRefresh),
    takeLatest(authAction.logout, handleLogout),
    takeLatest(authAction.register, handleRegister),
    takeLatest(authAction.emailConfirmationLink, handleEmailConfirmationLink),
  ]);
}

export default authSaga;
