import 'react-toastify/dist/ReactToastify.css';

import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import useAppropriateFavicon from 'src/hooks/useAppropriateFavicon';

import { LoadingGuard } from './common/LoadingGuardedRoute/LoadingGuardedRoute';
import EmailConfirmationPage from './pages/Auth/EmailConfirmation/EmailConfirmationPage';
import ForgotPasswordPage from './pages/Auth/ForgotPassword/ForgotPasswordPage';
import LoginPage from './pages/Auth/Login/LoginPage';
import PasswordResetPage from './pages/Auth/PasswordReset/PasswordResetPage';
import SignupPage from './pages/Auth/Signup/SignupPage';
import SearchDocumentPage from './pages/Search/SearchDocumentPage/SearchDocumentPage';
import SearchPromptPage from './pages/Search/SearchPromptPage/SearchPromptPage';
import SearchResultPage from './pages/Search/SearchResultPage/SearchResultPage';
import RedirectToAppropriateRoute from './RedirectToAppropriateRoute';
import { IdentifyUser } from './IdentifyUser';


function AuthRoutes() {
  return (
    <Routes>
      <Route
        index
        element={
          <LoadingGuard shouldRefresh>
            <LoginPage />
          </LoadingGuard>
        }
      />
      <Route
        path="/signup"
        element={
          <LoadingGuard>
            <SignupPage />
          </LoadingGuard>
        }
      />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<PasswordResetPage />} />
      <Route path="/confirm-email" element={<EmailConfirmationPage />} />
    </Routes>
  );
}

const SearchRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <LoadingGuard shouldRefresh requiresAuth>
            <SearchPromptPage />
          </LoadingGuard>
        }
      />
      <Route
        path="/search/:searchId"
        element={
          <LoadingGuard shouldRefresh requiresAuth>
            <SearchResultPage />
          </LoadingGuard>
        }
      />
      <Route
        path="/search/:searchId/:documentId"
        element={
          <LoadingGuard shouldRefresh requiresAuth>
            <SearchDocumentPage />
          </LoadingGuard>
        }
      />
      <Route path="/auth/*" element={<AuthRoutes />} />
    </Routes>
  );
};


export const AllRoutes: FC = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <Routes location={location}>
        <Route path="/*" element={<SearchRoutes />} />
        <Route path="/auth/*" element={<AuthRoutes />} />
      </Routes>
    </TransitionGroup>
  );
};


export const RootComponent: FC = () => {
  useAppropriateFavicon();

  return (
    <Router>
      <RedirectToAppropriateRoute />
      <IdentifyUser />
      <AllRoutes />
    </Router>
  );
};
