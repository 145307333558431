import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingCover from 'src/components/common/LoadingCover/LoadingCover';
import { authAction } from 'src/redux/auth/authReducer';
import {
  selectHasRefreshed,
  selectIsLoggingOut,
  selectIsRefreshing,
  selectResourceHasLoaded,
  selectUserIsLoggedIn,
} from 'src/redux/selectors';

type Props = {
  shouldRefresh?: boolean;
  requiresAuth?: boolean;
};

export const LoadingGuard: FC<PropsWithChildren<Props>> = ({
  children,
  requiresAuth,
  shouldRefresh,
}) => {
  const dispatch = useDispatch();
  const hasRefreshed = useSelector(selectHasRefreshed);
  const isRefreshing = useSelector(selectIsRefreshing);
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);
  const resourcesHasLoaded = useSelector(selectResourceHasLoaded);
  const isLoggingOut = useSelector(selectIsLoggingOut);

  const isLoading = !!(
    (shouldRefresh && !hasRefreshed) ||
    (requiresAuth && !userIsLoggedIn) ||
    isRefreshing ||
    isLoggingOut ||
    (userIsLoggedIn && !resourcesHasLoaded)
  );

  const wasLoadingRef = useRef<boolean>(isLoading);
  const wasLoading = wasLoadingRef.current;

  useEffect(() => {
    wasLoadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    if (shouldRefresh && !isRefreshing && !hasRefreshed) {
      dispatch(authAction.refresh());
    }
  }, []);

  return (
    <>
      {children}
      <LoadingCover
        className={`
                    fixed inset-0 
                    ${isLoading ? 'pointer-events-auto' : 'pointer-events-none'}
                    ${
                      isLoading
                        ? wasLoading
                          ? 'opacity-100'
                          : 'animate-fade-in'
                        : wasLoading
                          ? 'animate-fade-out'
                          : 'opacity-0'
                    }
                `}
      />
    </>
  );
};
