import { FC, ReactNode } from 'react';

const Tag: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => {
  const defaultBg = !!className?.match(/ bg-/) ? '' : 'bg-qura-btn-hover';

  return (
    <div className={`${defaultBg} flex items-center rounded px-2 py-1.5  text-xs ${className}`}>
      {children}
    </div>
  );
};

export default Tag;
