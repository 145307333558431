import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { useEffect, useRef, useState } from 'react';
import { Document, Page as ReactPDFPage } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import { useVirtualizer } from '@tanstack/react-virtual';
import LoadingGradient from 'src/components/pages/Search/partials/LoadingGradient/LoadingGradient';
import { ApiDocumentPageMeta } from 'src/types/api';

import { PDFPageLoading } from './PDFPageLoading';

const Page = ({
  index,
  width,
  height,
  scale,
}: {
  index: number;
  width: number;
  height: number;
  scale: number;
}) => {
  return (
    <ReactPDFPage
      loading={
        <LoadingGradient className=" rounded-none opacity-60" width={width} height={height} />
      }
      error={<PDFPageLoading width={width} height={height} />}
      pageIndex={index}
      width={width}
      scale={scale}
    />
  );
};

const VirtualPageList = ({
  pageCount,
  pageMeta,
  pageNumber,
  opaque,
  setCurrentPage,
  scale,
}: {
  pageCount: number;
  pageMeta: ApiDocumentPageMeta[];
  pageNumber: { number: number; id: number };
  opaque: boolean;
  scale: number;
  setCurrentPage: (value: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: pageCount,
    getScrollElement: () => ref.current,
    estimateSize: (i: number) => (pageMeta[i]?.height || 0) * scale,
    overscan: 10,
    gap: 10,
  });
  const items = virtualizer.getVirtualItems();

  useEffect(() => {
    setCurrentPage(virtualizer.range?.startIndex ?? 0);
  }, [setCurrentPage, virtualizer.range?.startIndex]);

  useEffect(() => {
    const offset = virtualizer.getOffsetForIndex(pageNumber.number, 'start');
    virtualizer.scrollToOffset(offset[0] + 10);
  }, [pageNumber.id, virtualizer]);

  return (
    <div
      ref={ref}
      className={`h-full w-full overflow-y-auto overflow-x-auto flex justify-center [contain:strict]  bg-qura-btn-hover rounded-b-xl ${
        opaque ? 'opacity-50 ' : ''
      } `}>
      <div style={{ height: virtualizer.getTotalSize() }} className={`relative w-full`}>
        {items.map((virtualItem) => {
          const pageHeight = pageMeta[virtualItem.index]?.height;
          const pageWidth = pageMeta[virtualItem.index]?.width;

          if (pageHeight && pageWidth) {
            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
                className={`${
                  virtualItem.index % 2 ? 'ListItemOdd' : 'ListItemEven'
                } top-0 left-0 right-0 mr-auto ml-auto absolute`}
                style={{
                  width: pageWidth * scale,
                  height: pageHeight * scale,
                  transform: `translateY(${virtualItem.start}px)`,
                }}>
                <Page
                  index={virtualItem.index}
                  width={pageWidth}
                  height={pageHeight}
                  scale={scale}
                />
              </div>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

const Virtualizer = ({
  file,
  pageCount,
  pageMetadata,
  pageNumber,
  opaque,
  setCurrentPage,
  scale,
}: {
  file: {
    url: string;
  };
  pageNumber: { number: number; id: number };
  pageCount: number;
  pageMetadata: ApiDocumentPageMeta[];
  opaque: boolean;
  setCurrentPage: (value: number) => void;
  scale: number;
}) => {
  const [pdf, setPdf] = useState<DocumentCallback | null>(null);

  function onDocumentLoadSuccess(nextPdf: DocumentCallback) {
    setPdf(nextPdf);
  }

  return (
    <Document
      file={file}
      loading={
        <div className="w-full flex justify-center">
          <LoadingGradient
            className=" rounded-none opacity-60 h-full"
            width={((pageMetadata[0]?.width ?? 0) * scale) / 100}
          />
        </div>
      }
      onLoadSuccess={onDocumentLoadSuccess}>
      {pdf ? (
        <VirtualPageList
          setCurrentPage={setCurrentPage}
          pageNumber={pageNumber}
          pageCount={pageCount}
          pageMeta={pageMetadata}
          opaque={opaque}
          scale={scale}
        />
      ) : null}
    </Document>
  );
};
export default Virtualizer;
