import './assets/fonts/fonts.scss';
import './styles/global.scss';
import './styles/tailwind.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { PostHogProvider } from 'posthog-js/react';

import { RootComponent } from './components/Root';
import { env } from './env';
import store from './redux/store';
import { getBrowserId, getSessionId } from './utilities/getSessionId';
import { PostHogConfig } from 'posthog-js';

const posthogOptions: Partial<PostHogConfig> = {
  api_host: env.REACT_APP_PUBLIC_POSTHOG_HOST,
  loaded: (posthog) => {
    posthog.register({
      qura_browser_id: getBrowserId(),
      qura_session_id: getSessionId(),
    });
  }
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
      <Provider store={store}>
        <RootComponent />
      </Provider>
    </PostHogProvider>
  </React.StrictMode>,
);
