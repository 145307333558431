import { useCallback, useEffect, useRef } from 'react';

const BOTTOM_SCROLL_MARGIN = 100;

type ScrollEvents = {
  onScroll?: (e: Event) => void;
  onBottom?: () => void;
};

const useOnScroll = (events: ScrollEvents) => {
  const { onScroll, onBottom } = events;

  const wasOnBottom = useRef(false);

  const handleScroll = useCallback(
    (e: Event) => {
      if (onScroll) {
        onScroll(e);
      }

      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight - BOTTOM_SCROLL_MARGIN) {
        if (onBottom && wasOnBottom.current === false) {
          onBottom();
        }
        wasOnBottom.current = true;
      } else {
        wasOnBottom.current = false;
      }
    },
    [onBottom, onScroll],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
};

export default useOnScroll;
