import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { env } from 'src/env';
import { selectSearchIsLoading } from 'src/redux/selectors';
import { ApiDocumentMeta } from 'src/types/api';

import { PDFPageLoading } from './PDFPageLoading';
import { PDFReaderTopBar } from './PDFReaderTopBar';
import Virtualizer from './Virtualizer';

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api/v1'
    : env.REACT_APP_API_URL;

const PDFReader = ({
  pageNumber,
  legalId,
  documentMeta,
  documentMetaLoading,
}: {
  pageNumber: { number: number; id: number };
  legalId?: string;
  documentMeta?: ApiDocumentMeta;
  documentMetaLoading: boolean;
}) => {
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [scale, setScale] = useState(100);
  const { searchId, documentId } = useParams();
  const searchIsLoading = useSelector(selectSearchIsLoading);
  const fileUrl = `${baseUrl}/database/${documentId}/${searchId}/get`;
  const [currentPage, setCurrentPage] = useState(pageNumber.number);
  const [scrollToPage, setScrollToPage] = useState(pageNumber);

  useEffect(() => {
    setScrollToPage(pageNumber);
  }, [pageNumber.id]);

  const file = useMemo(
    () => ({
      url: fileUrl,
    }),
    [fileUrl],
  );

  return (
    <>
      <div className="grid grid-cols-1 grid-rows-[3.5rem_1fr] rounded-xl shadow-qura h h-full w-full bg-white overflow-clip">
        <PDFReaderTopBar
          legalId={legalId}
          scale={scale}
          setScale={setScale}
          downloadOpen={downloadOpen}
          setDownloadOpen={setDownloadOpen}
          currentPage={currentPage}
          setScrollToPage={setScrollToPage}
          pageCount={documentMeta?.pageCount || 0}
        />
        {documentMeta && !searchIsLoading && !documentMetaLoading ? (
          <Virtualizer
            pageNumber={scrollToPage}
            file={file}
            pageCount={documentMeta.pageCount}
            pageMetadata={documentMeta.pageMetadata}
            opaque={downloadOpen}
            setCurrentPage={setCurrentPage}
            scale={(scale / 100) * documentMeta.scaleFactor}
          />
        ) : (
          <div className="flex items-center justify-center animate-pulse">
            <PDFPageLoading width={200} height={200} />
          </div>
        )}
      </div>
    </>
  );
};

export default PDFReader;
