import { all, call, debounce, delay, put, select, takeLatest } from 'redux-saga/effects';
import { authAction } from 'src/redux/auth/authReducer';
import { createErrorSagaHandle } from 'src/redux/reduxHelpers';
import {
  selectCurrentSearch,
  selectSearchParameters,
  selectSearchResults,
  selectSubmittedParams,
} from 'src/redux/selectors';
import {
  CHUNK_COUNT_LIMIT,
  DOCUMENT_PER_REQUEST,
  extendSearchRequest,
  getSearchResultsRequest,
  MAX_DOCUMENTS_PER_SEARCH,
  newSearchRequest,
} from 'src/services/api/apiService';
import { ApiResultPageErrorCode, apiResultPageErrorCodes } from 'src/services/api/apiValidation';
import { ApiResultPage, ApiSearchParams, ApiSearchResult, ApiSearchState } from 'src/types/api';
import { createAppError } from 'src/types/appError';

import { searchAction } from './searchReducer';

const handleNewSearch = createErrorSagaHandle(function* () {
  const parameters: ApiSearchParams = yield select(selectSearchParameters);
  const submittedParams = yield select(selectSubmittedParams);

  if (parameters === submittedParams) {
    yield put(
      searchAction.newSearchFailed(
        createAppError(
          'identical-request',
          'New search request is the same as the previous search request.',
        ),
      ),
    );
    yield put(searchAction.resetResultPages());
  } else {
    const limits = {
      documentCount: DOCUMENT_PER_REQUEST,
      chunkCountLimit: CHUNK_COUNT_LIMIT,
    };

    const response: ApiSearchState = yield call(newSearchRequest, parameters, limits);

    yield put(searchAction.newSearchSuccess(response));
  }
  yield put(searchAction.getResults());
}, searchAction.newSearchFailed);

const handleExtendSearch = createErrorSagaHandle(function* () {
  const pages: ApiResultPage[] = yield select(selectSearchResults);
  const lastCompletePage = [...pages].reverse().find((p) => p.status === 'COMPLETE');
  const lastPageDocumens = lastCompletePage?.result?.result.documents.length ?? 0;
  const totalDocuments = pages.reduce(
    (acc, p) => acc + (p.result?.result.documents.length ?? 0),
    0,
  );
  if (lastPageDocumens < DOCUMENT_PER_REQUEST || totalDocuments >= MAX_DOCUMENTS_PER_SEARCH) {
    yield put(
      searchAction.extendSearchFailed(
        createAppError(
          'unimportant',
          'Cannot extend search, last page is not complete or does not contain enough documents to extend search.',
        ),
      ),
    );
    return;
  }

  const currentSearch: ApiSearchState = yield select(selectCurrentSearch);
  const limits = {
    documentCount: DOCUMENT_PER_REQUEST,
    chunkCountLimit: CHUNK_COUNT_LIMIT,
  };

  const response: ApiSearchState = yield call(extendSearchRequest, currentSearch, limits);

  yield put(searchAction.extendSearchSuccess(response));
  yield put(searchAction.getResults());
}, searchAction.extendSearchFailed);

const POLL_DELAY = 1000;
const TIMEOUT = 1000 * 60;
const handleGetResults = createErrorSagaHandle(function* () {
  const startTime = Date.now();
  while (true) {
    yield delay(POLL_DELAY);

    const search: ApiSearchState | null = yield select(selectCurrentSearch);
    if (search === null) {
      throw createAppError('unimportant', "Search has been cleared.");
    }

    const response: ApiSearchResult = yield call(getSearchResultsRequest, search);

    if (response.pages.length === 0) {
      throw createAppError('unknown', 'Search failed with no pages returned');
    }

    yield put(searchAction.getResultsSuccess(response));

    if (response.pages.some((p) => p.status === 'CANCELLED')) {
      break;
    }

    if (response.pages.some((p) => p.status === 'ERROR')) {
      const errors = response.pages
        .map((p) => p.errorCode)
        .filter((p) => p !== null && p !== undefined)
        .map((p) => apiResultPageErrorCodes[p as ApiResultPageErrorCode]);

      const errorString = `Search failed with error${
        errors.length > 1 ? 's' : ''
      } ${errors.join(', ')}`;

      throw createAppError('unknown', errorString);
    }

    if (response.pages.every((p) => p.status === 'COMPLETE')) {
      break;
    }

    if (Date.now() - startTime > TIMEOUT) {
      throw createAppError('unknown', 'Search timed out when getting results');
    }
  }
}, searchAction.getResultsFailed);

function* handleLogOut() {
  yield put(searchAction.clear());
}

function* handleNewSearchDebounce() {
  yield put(searchAction.newSearch());
}

function* handleFilterChange(): Generator {
  yield put(searchAction.newSearchDebounce());
}

function* handleLoadSearch(): Generator {
  yield put(searchAction.getResults());
}

export function* searchSaga() {
  yield all([
    takeLatest(searchAction.getResults, handleGetResults),
    takeLatest(authAction.logoutSuccess, handleLogOut),
    takeLatest(searchAction.loadSearch, handleLoadSearch),
    takeLatest(searchAction.newSearch, handleNewSearch),
    takeLatest(searchAction.extendSearch, handleExtendSearch),
    debounce(1000, searchAction.newSearchDebounce, handleNewSearchDebounce),
    // takeLatest(searchAction.setDateFilter, handleFilterChange),
    // takeLatest(searchAction.resetFilter, handleFilterChange),
    // takeLatest(searchAction.addTagCombinationToFilter, handleFilterChange),
    // takeLatest(searchAction.removeTagCombinationToFilter, handleFilterChange),
  ]);
}
