import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import React, { FC, ReactNode } from 'react';
import { Document, Page } from 'react-pdf';
import Tag from 'src/components/pages/Search/partials/Tag/Tag';

type Props = {
  pdfExcerpt: string | null;
  pageNumber?: number;
  className?: string;
  renderTextLayer?: boolean;
  score?: number;
  relevanceLevel?: number | null;
  width?: number;
  scale?: number;
  upperRightTag?: ReactNode;
};

const ResultChunk: FC<Props> = ({
  pdfExcerpt,
  pageNumber,
  className,
  score,
  relevanceLevel,
  renderTextLayer = true,
  width,
  scale,
  upperRightTag,
}) => {
  return (
    <div className={'flex-1 w-full flex justify-center relative overflow-clip ' + className}>
      <div className="absolute top-1 right-1 z-[3] ">{upperRightTag}</div>
      <Document
        error="Failed to load preview"
        loading="Loading preview"
        file={`data:application/pdf;base64,${pdfExcerpt}`}>
        <Page
          pageNumber={1}
          width={width}
          scale={scale}
          renderAnnotationLayer={false}
          renderTextLayer={renderTextLayer}
        />
      </Document>
      {pageNumber ? (
        <Tag
          className={`absolute right-1 bottom-1 ${
            relevanceLevel !== undefined && relevanceLevel !== null
              ? relevanceLevel == 1
                ? ' bg-green-100 text-green-700'
                : 'bg-yellow-100 text-yellow-700'
              : 'bg-qura-btn-hover'
          } `}>
          s.{pageNumber}
        </Tag>
      ) : null}
    </div>
  );
};

export default ResultChunk;
