import axios from 'axios';
import { env } from 'src/env';
import { createAppError } from 'src/types/appError';
import { Company, LoginCredentials, User } from 'src/types/auth';
import devlog from 'src/utilities/devlog';
import { standardAxiosOptions } from '../axiosOptions';

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api/v1/auth'
    : env.REACT_APP_STRAPI_URL;
devlog('STRAPI URL', baseUrl);

const getStrapiErrorMessage = (error: any): string => {
  return error?.response?.data?.error?.message ?? 'Unknown Strapi Error';
};

const mapUser = (data: any): User => {
  return {
    id: data.id,
    name: data.username,
    email: data.email,
    company: {
      id: data.company.id,
      company: data.company.company,
    },
  };
}

export async function signupStrapi(
  name: string,
  email: string,
  password: string,
  token: string,
): Promise<LoginCredentials> {
  try {
    await axios.post(`${baseUrl}/api/signup-token/signup`, {
      name,
      email,
      password,
      token,
    }, standardAxiosOptions());

    return {
      password,
      email,
    };
  } catch (error) {
    const message = getStrapiErrorMessage(error);

    if (message.match(/token/gi)) {
      throw createAppError('invalid-signup-token', 'Invalid signup token in signup used in signup.');
    }

    if (message.match(/unique/gi)) {
      throw createAppError('email-already-used', 'Email is already used in signup.');
    }

    throw createAppError('unknown', `Failed to sign up with Strapi. Strapi Errror Message: ${message}`);
  }
}

export async function loginStrapi(email: string, password: string): Promise<User> {
  let response;

  try {
    response = await axios.post(`${baseUrl}/api/auth/login`, { email, password }, standardAxiosOptions());
  } catch (error) {
    const message = getStrapiErrorMessage(error);
    if (message.match(/identifier|password|credentials/gi)) {
      throw createAppError(
        'invalid-credentials',
        'Failed to log in with Strapi, Invlaid Credentials',
      );
    } else if (message.match(/confirm/gi)) {
      throw createAppError('email-not-confirmed', 'Email not confirmed');
    }

    throw createAppError('unknown', 'Failed to log in with Strapi. Strapi Errror Message: ' + message);
  }

  if (response.data.jwt) {
    localStorage.setItem('jwt', response.data.jwt);
  }

  return mapUser(response.data.user)
}

export async function logoutStrapi() {
  localStorage.removeItem('jwt');
  try {
    await axios.post(`${baseUrl}/api/auth/logout`, {}, standardAxiosOptions());
  } catch (error) {
    const message = getStrapiErrorMessage(error);
    throw createAppError('unknown', 'Failed to log out with Strapi. Strapi Errror Message: ' + message);
  }
}

export async function refreshStrapi(): Promise<User> {
  try {
    const response = await axios.get(`${baseUrl}/api/users/me`, standardAxiosOptions());
    return mapUser(response.data);
  } catch (error) {
    const message = getStrapiErrorMessage(error);
    throw createAppError('unknown', 'Failed to refresh Strapi. Strapi Errror Message: ' + message);
  }
}

export async function resetPasswordStrapi(
  code: string,
  password: string,
  passwordConfirmation: string,
) {
  try {
    await axios.post(`${baseUrl}/api/auth/reset-password`, { password, passwordConfirmation, code }, standardAxiosOptions());
  } catch (error) {
    const message = getStrapiErrorMessage(error);
    throw createAppError('unknown', 'Failed to reset password with Strapi. Strapi Errror Message: ' + message);
  }
}

export async function forgotPasswordStrapi(email: string) {
  try {
    await axios.post(`${baseUrl}/api/auth/forgot-password`, { email }, standardAxiosOptions());
  } catch (error) {
    const message = getStrapiErrorMessage(error);
    throw createAppError('unknown', 'Failed to send forgot password email with Strapi. Strapi Errror Message: ' + message);
  }
}

export async function sendEmailConfirmationStrapi(email: string) {
  try {
    await axios.post(`${baseUrl}/api/auth/send-email-confirmation`, { email }, standardAxiosOptions());
    return email;
  } catch (error) {
    const message = getStrapiErrorMessage(error);
    throw createAppError('unknown', 'Failed to send email confirmation with Strapi. Strapi Errror Message: ' + message);
  }
}

export async function getSignupTokenCompanyStrapi(token: string): Promise<Company> {
  try {
    const response = await axios.get(`${baseUrl}/api/signup-token/company/${token}`, standardAxiosOptions());
    return {
      id: response.data.id,
      company: response.data.company,
    };
  } catch (error) {
    const message = getStrapiErrorMessage(error);
    throw createAppError('unknown', 'Failed to get signup token company with Strapi. Strapi Errror Message: ' + message);
  }
}
