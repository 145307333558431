import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

export const getBrowserId = (): string => {
    let browserId = Cookies.get('qura_browser_id');
    if (browserId) {
        return browserId;
    }

    browserId = uuidv4();
    Cookies.set('qura_browser_id', browserId, { expires: 365 });
    return browserId;
};


export const getSessionId = (): string => {
    const sessionStorageSessionId = sessionStorage.getItem('qura_session_id');
    if (sessionStorageSessionId) {
        return sessionStorageSessionId;
    }

    const sessionId = uuidv4();
    sessionStorage.setItem('qura_session_id', sessionId);
    Cookies.set('qura_session_id', sessionId, { expires: 1/24 });
    return sessionId;
};