import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Icons } from 'src/assets';
import Tag from 'src/components/pages/Search/partials/Tag/Tag';
import { selectTagIdLabelDict } from 'src/redux/selectors';
import { ApiResultDocument } from 'src/types/api';

type Props = {
  resultDocument: ApiResultDocument;
  className?: string;
};

const MappedTags = ({ tags }: { tags: string[] }) => {
  const tagIdLabelDict = useSelector(selectTagIdLabelDict);

  const mappedTags = useMemo(() => {
    return tags.map((tagId) => tagIdLabelDict[tagId]).filter(Boolean);
  }, [tags, tagIdLabelDict]);

  return (
    <>
      {mappedTags.map((tag, index) => (
        <Tag key={index}>
          <p className="">{tag}</p>
        </Tag>
      ))}
    </>
  );
};

const ResultDocumentTop: FC<Props> = ({ resultDocument, className = '' }) => {
  return (
    <div className={'flex justify-between items-center ' + className}>
      <div className="flex flex-wrap items-center gap-3">
        <Tag className="border border-qura-ternary-text">
          <Icons.Book className="mr-2 mb-[1px]" />
          <p className=" font-medium">{resultDocument.legalId}</p>
        </Tag>
        {resultDocument.metadata.year ? (
          <Tag>
            <Icons.Calendar className="mr-2 mb-[1px]" />
            {resultDocument.metadata.month && resultDocument.metadata.day ? (
              <p className="">
                {resultDocument.metadata.year}-
                {String(resultDocument.metadata.month).padStart(2, '0')}-
                {String(resultDocument.metadata.day).padStart(2, '0')}
              </p>
            ) : (
              <p className="">{resultDocument.metadata.year}</p>
            )}
          </Tag>
        ) : null}
        <MappedTags tags={resultDocument.metadata.tags} />
      </div>
    </div>
  );
};

export default ResultDocumentTop;
