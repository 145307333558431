import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Icons } from 'src/assets';
import ErrorMessage from 'src/components/common/ErrorMessage/ErrorMessage';
import Feedback from 'src/components/pages/Search/partials/Feedback/Feedback';
import SearchBar from 'src/components/pages/Search/partials/SearchBar/SearchBar';
import UserDropDown from 'src/components/pages/Search/partials/UserDropDown/UserDropDown';
import { searchAction } from 'src/redux/search/searchReducer';
import { selectCurrentSearch, selectSearchError, selectSearchQuery } from 'src/redux/selectors';

const EXAMPLE_PROMPTS = [
  'Kan en aktieägare bli personligt betalningsansvarig?',
  'Under vilka förutsättningar är kostnader för sponsring avdragsgilla enligt inkomstskattelagen 16 kap. 1 §?',
  'Visa fall med liknande omständigheter: köp av lös egendom, överlåtelseförbud, skadestånd, tredjeman.',
];

const ExamplePrompts = () => {
  const dispatch = useDispatch();

  const searchWithPrompt = (prompt: string) => {
    dispatch(searchAction.setQuery({ query: prompt }));
    dispatch(searchAction.newSearch());
  };

  return (
    <div className="flex flex-col items-start gap-3 mt-10 text-xs">
      <p className=" text-qura-ternary-text">Ask in natural language what you are searching for</p>

      {EXAMPLE_PROMPTS.map((prompt, index) => {
        return (
          <button
            key={index}
            onClick={() => searchWithPrompt(prompt)}
            className="flex bg-qura-white px-2 py-3 shadow-qura hover:bg-qura-btn-hover rounded-md gap-2">
            <Icons.ArrowTiltedUpRight className=" text-qura-ternary-text" />
            <p className="text-qura-primary-text text-left font-medium">{prompt}</p>
          </button>
        );
      })}
    </div>
  );
};

const SearchBox = () => {
  const dispatch = useDispatch();
  const query = useSelector(selectSearchQuery);
  const searchError = useSelector(selectSearchError);
  const displayError = searchError ? searchError.type !== 'identical-request' && searchError.type !== 'unimportant' : false;

  const onQueryChange = (query: string) => {
    dispatch(searchAction.setQuery({ query }));
  };

  const startSearch = () => {
    dispatch(searchAction.newSearch());
  };
  return (
    <div className="w-3/5">
      <SearchBar value={query} setValue={onQueryChange} onSearch={startSearch} disabled={false} />
      {searchError && displayError && (
        <div className="flex flex-col gap-2 items-center mt-4">
          <ErrorMessage error={searchError} className="text-xs text-red-500" />
          <button
            className="bg-qura-white border border-qura-border text-qura-primary-text hover:bg-qura-btn-hover text-xs px-2 py-1.5 rounded"
            onClick={() => dispatch(searchAction.newSearch())}>
            Try again
          </button>
        </div>
      )}
      <ExamplePrompts />
    </div>
  );
};

const SearchPromptPage = () => {
  const navigate = useNavigate();
  const search = useSelector(selectCurrentSearch);

  useEffect(() => {
    if (search?.searchId) {
      navigate(`/search/${search.searchId}`);
    }
  }, [navigate, search]);

  return (
    <>
      <div className="fixed right-10 top-10">
        <UserDropDown />
      </div>
      <div className="flex flex-col justify-center items-center h-screen w-screen gap-8">
        <Icons.LogoText className="h-5" />
        <SearchBox />
      </div>
      <Feedback />
      <ToastContainer className="pointer-events-auto" />
    </>
  );
};

export default SearchPromptPage;
