import React, { FC } from 'react';
import { Icons } from 'src/assets';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: typeof Icons.LogoText;
  iconClassName?: string;
}

const AuthTextInput: FC<Props> = (props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const inputProps = { ...props };
  delete inputProps.iconClassName;

  return (
    <div
      className={`flex h-9 mb-3 px-2 items-center border border-dark-border rounded-smaller  ${isFocused ? 'bg-dark-border' : ''}`}>
      <props.icon
        className={`text-white w-4 ${isFocused ? '' : 'opacity-50'} ${props.iconClassName}`}
      />
      <input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`font-inter font-medium text-small bg-transparent rounded-smaller w-full py-2 px-2 text-white leading-tight focus:outline-none`}
        {...inputProps}
        style={{
          transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
        }}
      />
    </div>
  );
};

export default AuthTextInput;
