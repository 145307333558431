import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icons } from 'src/assets';
import Tag from 'src/components/pages/Search/partials/Tag/Tag';
import { env } from 'src/env';

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api/v1'
    : env.REACT_APP_API_URL;

const DebounceInput = ({
  pageCount,
  setDebouncedValue,
  debouncedValue,
}: {
  pageCount: number;
  setDebouncedValue: (value: { number: number; id: number }) => void;
  debouncedValue: number;
}) => {
  const [inputValue, setInputValue] = useState(1);
  const [debounce, setDebounce] = useState(true);

  useEffect(() => {
    setInputValue(debouncedValue);
    setDebounce(false);
  }, [debouncedValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const int = parseInt(event.target.value) - 1;
    setDebounce(true);
    return setInputValue(int);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (debounce) {
      timeoutId = setTimeout(() => {
        if (isNaN(inputValue)) return { number: 0, id: Math.random() };
        if (inputValue < 0) return setDebouncedValue({ number: 0, id: Math.random() });
        if (inputValue > (pageCount ?? 0))
          return setDebouncedValue({
            number: pageCount ?? 0,
            id: Math.random(),
          });

        setDebouncedValue({ number: inputValue, id: Math.random() });
      }, 500);
    }
    return () => clearTimeout(timeoutId);
  }, [debounce, inputValue, pageCount, setDebouncedValue]);

  return (
    <input
      type="number"
      className="w-fit text-xs rounded text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:outline-none ring-1 ring-qura-border hover:ring-qura-primary-text focus:ring-qura-primary-text mr-1 "
      min={1}
      max={pageCount}
      value={inputValue + 1}
      onChange={handleInputChange}
    />
  );
};

const ZoomInput = ({ scale, setScale }: { scale: number; setScale: (value: number) => void }) => {
  return (
    <div className="flex">
      <label
        className=" bg-qura-btn-hover flex justify-center items-center rounded-l-small border-t border-b border-l border-qura-border text-qura-primary-text peer-focus:border-qura-primary-text text-xs py-1.5 w-6"
        htmlFor="scalepecentage">
        <Icons.Search className="size-3.5" />
      </label>
      <input
        className=" w-max bg-qura-btn-hover border caret-transparent border-qura-border text-qura-primary-text hover:bg-qura-white text-xs px-1 py-1.5 focus:border-qura-primary-text outline-none  cursor-default "
        id="scalepercentage"
        type="number"
        value={scale}
        min={50}
        max={300}
        step={10}
        onChange={(event) => setScale(parseFloat(event.target.value) || 100)}
        onKeyDown={(e) => e.preventDefault()}
      />
      <label
        htmlFor="scalepercentage"
        className="w-6 bg-qura-btn-hover flex justify-center items-center  rounded-r-small  border-qura-border border-t border-b border-r text-qura-primary-text peer-focus:border-qura-primary-text text-xs  py-1.5">
        %
      </label>
    </div>
  );
};

const DownloadButton = ({
  downloadOpen,
  setDownloadOpen,
  legalId,
}: {
  downloadOpen: boolean;
  setDownloadOpen: (value: boolean) => void;
  legalId?: string;
}) => {
  const { searchId, documentId } = useParams();
  const downloadPDFButtonRef = useRef<HTMLDivElement>(null);

  const download = async (withHighlight: boolean) => {
    const response = await fetch(
      `${baseUrl}/database/${documentId}/${withHighlight ? searchId + '/' : ''}get`,
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${legalId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      const dropdownElement = downloadPDFButtonRef.current;
      if (
        dropdownElement &&
        event.target instanceof Node &&
        !dropdownElement.contains(event.target as Node) &&
        downloadOpen
      ) {
        setDownloadOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, [downloadOpen, setDownloadOpen]);

  return (
    <div ref={downloadPDFButtonRef}>
      {/* <div
        className={`absolute bg-qura-white rounded flex flex-col justify-start shadow-qura px-2 py-2 z-10 top-10 text-xs ${
          downloadOpen ? "visible" : "hidden"
        }`}
      >
        <button
          className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-btn-hover text-left"
          onClick={() => {
            download(true);
            setDownloadOpen(false);
          }}
        >
          <p>Download with highlights</p>
        </button>
        <button
          className=" rounded-[6px] p-2 cursor-pointer hover:bg-qura-btn-hover text-left"
          onClick={() => {
            download(false);
            setDownloadOpen(false);
          }}
        >
          <p>Download without highlights</p>
        </button>
      </div> */}
      <button
        className=" bg-qura-white border border-qura-border text-qura-primary-text hover:bg-qura-btn-hover text-xs px-2 py-1.5 rounded"
        // onClick={() => setDownloadOpen(!downloadOpen)}
        onClick={() => download(false)}>
        Download PDF
      </button>
    </div>
  );
};

export const PDFReaderTopBar = ({
  legalId,
  scale,
  setScale,
  downloadOpen,
  setDownloadOpen,
  currentPage,
  setScrollToPage,
  pageCount,
}: {
  legalId?: string;
  scale: number;
  setScale: (value: number) => void;
  downloadOpen: boolean;
  setDownloadOpen: (value: boolean) => void;
  currentPage: number;
  setScrollToPage: (value: { number: number; id: number }) => void;
  pageCount?: number;
}) => {
  return (
    <div className="border-b border-gray-200 flex gap-2 justify-between items-center px-7">
      <Tag className="border border-qura-ternary-text">
        <Icons.Book className="mr-2 mb-[1px]" />
        <p className=" font-medium">{legalId}</p>
      </Tag>
      <div className="flex">
        <DebounceInput
          debouncedValue={currentPage}
          setDebouncedValue={setScrollToPage}
          pageCount={pageCount ?? 0}
        />
        <p className="text-xs"> / {pageCount}</p>
      </div>
      <div className="flex items-center gap-4 relative">
        <DownloadButton
          legalId={legalId}
          downloadOpen={downloadOpen}
          setDownloadOpen={setDownloadOpen}
        />
        <ZoomInput scale={scale} setScale={setScale} />
      </div>
    </div>
  );
};
