import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Icons } from 'src/assets';
import AuthBackground from 'src/components/pages/Auth/partials/AuthBackground';
import AuthContainerCard from 'src/components/pages/Auth/partials/AuthContainerCard';
import AuthError from 'src/components/pages/Auth/partials/AuthError';
import AuthFooter from 'src/components/pages/Auth/partials/AuthFooter';
import AuthInputCard from 'src/components/pages/Auth/partials/AuthInputCard';
import AuthLogo from 'src/components/pages/Auth/partials/AuthLogo';
import AuthSubmitButton from 'src/components/pages/Auth/partials/AuthSubmitButton';
import AuthTextInput from 'src/components/pages/Auth/partials/AuthTextInput';
import AuthTitle from 'src/components/pages/Auth/partials/AuthTitle';
import { authAction } from 'src/redux/auth/authReducer';
import { resetPasswordStrapi } from 'src/services/strapi/strapiService';
import { AppError, createAppError } from 'src/types/appError';

const PasswordResetPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AppError | null>(null);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [recoverState, setRecoverState] = useState<{ code: string } | null>(null);

  useEffect(() => {
    dispatch(authAction.logout());
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      setRecoverState({ code });
    } else {
      setRecoverState(null);
    }
  }, [location]);

  const resetPasswordClick = () => {
    if (recoverState === null) {
      setError(createAppError('message', 'Your reset password link is invalid.'));
      return;
    }

    if (password !== confirmPassword) {
      setError(createAppError('message', 'Passwords do not match.'));
      return;
    }

    setIsLoading(true);
    setError(null);
    resetPasswordStrapi(recoverState.code, password, confirmPassword)
      .then(() => {
        setSuccess(true);
        navigate('/auth/reset-password');
      })
      .catch(() => setError(createAppError('unknown', 'Failed to reset password.')))
      .finally(() => setIsLoading(false));
  };

  const loginClick = () => {
    navigate('/auth');
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthLogo />
        <AuthInputCard>
          <AuthTitle>Reset Password</AuthTitle>
          {success ? (
            <>
              <p className="text-white text-sm tracking-wide mb-5 mt-2 text-center">
                Your password has been reset!
              </p>
              <AuthSubmitButton onClick={loginClick}>Back to Login</AuthSubmitButton>
            </>
          ) : (
            <>
              <AuthTextInput
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                value={password}
                placeholder="Enter new password"
                icon={Icons.Arrow}
                iconClassName="transform -rotate-90"
              />
              <AuthTextInput
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                id="confirm-password"
                value={confirmPassword}
                placeholder="Confirm new password"
                icon={Icons.Arrow}
                iconClassName="transform -rotate-90"
              />
              <button
                onClick={loginClick}
                className="-mt-2 mb-3 self-start text-medium text-white hover:underline text-inter text-small li">
                <Icons.Arrow className="transform rotate-90 h-[5px] mb-[2px] inline-block" />
                {'Login'}
              </button>
              <AuthSubmitButton isLoading={isLoading} onClick={resetPasswordClick}>
                Reset Password
              </AuthSubmitButton>
            </>
          )}
        </AuthInputCard>
        <AuthError error={error} />
        <AuthFooter />
      </AuthContainerCard>
      <ToastContainer />
    </AuthBackground>
  );
};

export default PasswordResetPage;
