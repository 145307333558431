import React, { FC } from 'react';
import { ApiResultDocument } from 'src/types/api';

type Props = {
  resultDocument: ApiResultDocument;
  hidePage?: boolean;
};

const DocumentTitlePage: FC<Props> = ({ resultDocument, hidePage }) => {
  const title = resultDocument.metadata.title || 'No title';
  // const relevance = resultDocument?.score || 0;

  // // Function to determine the color based on the relevance score
  // const getRelevanceColor = (score: number) => {
  //   if (score > 0.8) return "bg-green-300"; // High relevance
  //   if (score > 0.5) return "bg-yellow-300"; // Medium relevance
  //   return "bg-red-300"; // Low relevance
  // };

  return (
    <div className="flex items-center justify-between">
      <p className={'font-medium flex-1 '}>{title}</p>
      {/* <div className="flex items-center">
         Relevance score display 
        <div
          className={`rounded-smaller text-xs px-2 py-[6px] ${getRelevanceColor(
            relevance
          )}`}
        >
          <p>Score: {relevance}</p>
        </div>
      </div> */}
    </div>
  );
};

export default DocumentTitlePage;
