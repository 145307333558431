import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Icons } from 'src/assets';
import AuthBackground from 'src/components/pages/Auth/partials/AuthBackground';
import AuthContainerCard from 'src/components/pages/Auth/partials/AuthContainerCard';
import AuthError from 'src/components/pages/Auth/partials/AuthError';
import AuthFooter from 'src/components/pages/Auth/partials/AuthFooter';
import AuthInputCard from 'src/components/pages/Auth/partials/AuthInputCard';
import AuthLogo from 'src/components/pages/Auth/partials/AuthLogo';
import AuthSubmitButton from 'src/components/pages/Auth/partials/AuthSubmitButton';
import AuthTextInput from 'src/components/pages/Auth/partials/AuthTextInput';
import AuthTitle from 'src/components/pages/Auth/partials/AuthTitle';
import { authAction } from 'src/redux/auth/authReducer';
import { selectEmailInput } from 'src/redux/selectors';
import { forgotPasswordStrapi } from 'src/services/strapi/strapiService';
import { AppError, createAppError } from 'src/types/appError';

const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AppError | null>(null);
  const [success, setSuccess] = useState(false);

  const email = useSelector(selectEmailInput);
  const dispatch = useDispatch();

  const recoverPasswordClick = () => {
    setIsLoading(true);
    setError(null);
    forgotPasswordStrapi(email)
      .then(() => setSuccess(true))
      .catch(() => setError(createAppError('unknown', 'Failed to send reset password email.')))
      .finally(() => setIsLoading(false));
  };

  const loginClick = () => {
    navigate('/auth');
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthLogo />
        <AuthInputCard>
          <AuthTitle>Forgot Password</AuthTitle>
          <div className="h-2" />

          <AuthTextInput
            onChange={(e) => dispatch(authAction.setEmailInput(e.target.value))}
            type="email"
            id="email"
            value={email}
            placeholder="Enter your email"
            icon={Icons.Mail}
          />
          <p className="text-white text-inter -mt-2 mb-4 text-center"></p>
          <button
            onClick={loginClick}
            className="-mt-2 mb-3 self-start text-medium text-white hover:underline text-inter text-small li">
            <Icons.Arrow className="transform rotate-90 h-[5px] mb-[2px] inline-block" />
            {'Login'}
          </button>
          <div className="h-2" />
          {success ? (
            <p className="font-inter text-small tracking-wide antialiased text-white font-semibold text-center px-6 py-3 rounded-smaller border border-dark-border">
              A link to reset your password has been sent to your email.
            </p>
          ) : (
            <AuthSubmitButton isLoading={isLoading} onClick={recoverPasswordClick}>
              Recover Password
            </AuthSubmitButton>
          )}
        </AuthInputCard>
        <AuthError error={error} />
        <AuthFooter />
      </AuthContainerCard>
      <ToastContainer />
    </AuthBackground>
  );
};

export default ForgotPasswordPage;
