import React, { FC, PropsWithChildren } from 'react';

interface Props {}

const AuthContainerCard: FC<PropsWithChildren<Props>> = ({ children }) => {
  return (
    <div className="flex relative flex-col px-7 py-6 max-w-sm w-full rounded-bigger shadow-lg">
      <div className="absolute inset-0 bg-gradient-to-b from-qura-blue/60 to-qura-purple/70 rounded-bigger" />
      {children}
    </div>
  );
};

export default AuthContainerCard;
