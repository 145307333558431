import { AppError, AppErrorType, appErrorTypes } from 'src/types/appError';

import { validateRecord } from './validateRecord';

function validateError(error: unknown): AppError {
  let type: AppErrorType = 'unknown';
  let message = 'An unknown error occurred';

  if (typeof error == 'object' && error !== null) {
    const errorObject = validateRecord(error, 'error');
    if (
      typeof errorObject.type === 'string' &&
      appErrorTypes.includes(errorObject.type as AppErrorType)
    ) {
      type = errorObject.type as AppErrorType;
    }

    if (typeof errorObject.message == 'string') {
      message = errorObject.message;
    }
  }

  if (type === 'unknown' && !navigator.onLine) {
    type = 'network';
  }

  return {
    type,
    message,
  };
}

export default validateError;
