import { AxiosRequestConfig } from "axios";

export const standardAxiosOptions = (): AxiosRequestConfig => {
    const headers: AxiosRequestConfig['headers'] = {
        'Content-Type': 'application/json',
    }

    let usedLocalJwt = false;
    if(process.env.NODE_ENV === 'development') {
        const localStorageJwt = localStorage.getItem('jwt');
        const foundJwtInLocalStorage = localStorageJwt && localStorageJwt.trim() !== '';
        if (foundJwtInLocalStorage) {
            headers['Authorization'] = `Bearer ${localStorageJwt}`;
            usedLocalJwt = true;
        }
    }

    return {
        timeout: 40000,
        headers,
        withCredentials: !usedLocalJwt
    }
};
