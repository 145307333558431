import { Base64 } from 'js-base64';
import { convertSnakeToCamelCaseObject } from 'src/utilities/snakeToCamelCase';
import { z } from 'zod';

export const apiLimitsSchema = z
  .object({
    document_count: z.number().int().nonnegative(),
    chunk_count_limit: z.number().int().nonnegative(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiFilterSchema = z
  .object({
    tags: z.array(z.array(z.string())).optional().nullable(),
    document_ids: z.array(z.string()).optional().nullable(),
    chunk_id: z.array(z.string()).optional().nullable(),
    legal_id: z.array(z.string()).optional().nullable(),
    language: z.array(z.string()).optional().nullable(),
    date_range: z.array(z.array(z.number()).length(3)).length(2).optional().nullable(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiSearchModeSchema = z
  .object({
    no_formatting: z.boolean(),
    no_highlight: z.boolean(),
    no_chunks: z.boolean(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiParamsSchema = z
  .object({
    query: z.string(),
    filter: apiFilterSchema.optional().nullable(),
    search_modes: apiSearchModeSchema.optional().nullable(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiResultChunkSchema = z
  .object({
    // chunk_id: z.string(),
    score: z.number(),
    page_number: z.number().int(),
    pdf_excerpt: z.string().refine(Base64.isValid).nullable(),
    excerpt_width: z.number().nullable(),
    excerpt_height: z.number().nullable(),
    text: z.string().nullable(),
    highlighted: z.boolean(),
    cropped: z.boolean(),
    scale_factor: z.number(),
    relevance_level: z.number().int().nullable(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiResultDocumentMetadataSchema = z
  .object({
    title: z.string(),
    tags: z.array(z.string()),
    year: z.number().int().optional().nullable(),
    month: z.number().int().optional().nullable(),
    day: z.number().int().optional().nullable(),
    language: z.string(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiResultDocumentSchema = z
  .object({
    document_id: z.string(),
    legal_id: z.string(),
    score: z.number(),
    metadata: apiResultDocumentMetadataSchema,
    page_url: z.string().optional().nullable(),
    file_extension: z.string().optional().nullable(),
    collected_at: z.number().int(),
    result_chunks: z.array(apiResultChunkSchema),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiNewSearchResponseSchema = z
  .object({
    search_id: z.string(),
    first_page_index: z.number().int(),
    result_url: z.string(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiExtendSearchResponseSchema = z
  .object({
    next_page_index: z.number().int(),
    result_url: z.string(),
  })
  .transform(convertSnakeToCamelCaseObject);

export enum ApiResultPageStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
}

export enum ApiResultPageErrorCode {
  UNKNOWN,
  UNAUTHORIZED,
  UNPROCESSABLE,
  INTERNAL,
  TIMED_OUT,
}

export const apiResultPageErrorCodes = [
  'UNKNOWN',
  'UNAUTHORIZED',
  'UNPROCESSABLE',
  'INTERNAL',
  'TIMED_OUT',
];

export const apiResultPageSchema = z
  .object({
    index: z.number().int(),
    limits: apiLimitsSchema,
    status: z.nativeEnum(ApiResultPageStatus),
    error_code: z.nativeEnum(ApiResultPageErrorCode).optional().nullable(),
    result: z
      .object({
        error: z.boolean(),
        result: z.object({
          documents: z.array(apiResultDocumentSchema),
        }),
      })
      .optional()
      .nullable()
      .transform(convertSnakeToCamelCaseObject),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiSearchResultSchema = z
  .object({
    search_id: z.string(),
    params: apiParamsSchema,
    search_engine_model: z.string().optional().nullable(),
    pages: z.array(apiResultPageSchema),
  })
  .transform(convertSnakeToCamelCaseObject);

const apiBaseTagSchema = z.object({
  id: z.string(),
  label: z.string(),
});

type ApiTag = z.infer<typeof apiBaseTagSchema> & {
  subtags?: ApiTag[] | null;
};

export const apiTagSchema: z.ZodType<ApiTag> = apiBaseTagSchema
  .extend({
    subtags: z
      .lazy(() => apiTagSchema.array())
      .optional()
      .nullable(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiResourcesSchema = z
  .object({
    environment: z.string(),
    filters: z
      .object({
        tags: z.array(apiTagSchema),
        date_range: z.array(z.array(z.number().int())),
        languages: z.array(z.string()),
      })
      .transform(convertSnakeToCamelCaseObject),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiDocumentPageMetaResult = z
  .object({
    aspect_ratio: z.number(),
    height: z.number(),
    page_number: z.number(),
    width: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const apiDocumentMetaResult = z
  .object({
    scale_factor: z.number().default(1.2),
    file_size: z.number(),
    page_count: z.number(),
    page_metadata: z.array(apiDocumentPageMetaResult),
  })
  .transform(convertSnakeToCamelCaseObject);
