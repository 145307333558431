import { FC } from 'react';
import DocumentTitlePage from 'src/components/pages/Search/partials/DocumentTitlePage/DocumentTitlePage';
import ResultCard from 'src/components/pages/Search/partials/ResultCard/ResultCard';
import ResultChunk from 'src/components/pages/Search/partials/ResultChunk/ResultChunk';
import ResultDocumentTop from 'src/components/pages/Search/partials/ResultDocumentTop/ResultDocumentTop';
import Tag from 'src/components/pages/Search/partials/Tag/Tag';
import { ApiResultDocument } from 'src/types/api';

type Props = {
  resultDocument: ApiResultDocument;
  onOpen?: (document: ApiResultDocument, chunk: number) => void;
};

const SearchResult: FC<Props> = ({ resultDocument, onOpen }) => {
  const firstChunk = resultDocument.resultChunks[0];

  if (!firstChunk) return;

  return (
    <div className="bg-qura-white shadow-qura p-6 py-5 rounded-lg flex flex-col gap-4">
      <ResultDocumentTop resultDocument={resultDocument} />
      <DocumentTitlePage resultDocument={resultDocument} />
      <ResultCard onClick={onOpen && (() => onOpen(resultDocument, 0))}>
        <ResultChunk
          pdfExcerpt={firstChunk.pdfExcerpt}
          pageNumber={firstChunk.pageNumber + 1}
          scale={1.2}
          upperRightTag={<Tag className=" hover:bg-qura-border">Open</Tag>}
        />
      </ResultCard>
    </div>
  );
};

export default SearchResult;
