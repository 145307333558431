import React, { FC } from 'react';
import { Icons } from 'src/assets';

interface Props {}

const AuthLogo: FC<Props> = () => {
  return (
    <div className="z-10 mb-8 self-center rounded-full p-5 shadow-lg shadow-blue/50 bg-qura-purple/10 border border-white/20">
      <div className="flex justify-center rounded-full px-3 py-5 border border-white/20 bg-gradient-to-tl from-qura-blue/80 to-qura-purple/10 hadow-lg shadow-qura-purple/20">
        <Icons.LogoText className="h-5 text-white" />
      </div>
    </div>
  );
};

export default AuthLogo;
