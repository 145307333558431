import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import ErrorMessage from 'src/components/common/ErrorMessage/ErrorMessage';
import { selectAuthError } from 'src/redux/selectors';
import { AppError } from 'src/types/appError';

import AuthInputCard from './AuthInputCard';

type Props = {
  error?: AppError | null;
};

const AuthError: FC<Props> = ({ error: stateError }) => {
  const reduxError = useSelector(selectAuthError);
  const error = stateError === undefined ? reduxError : stateError;

  return (
    <div>
      {error !== null && error.type !== 'unimportant' && error.type !== 'email-not-confirmed' && (
        <>
          <div className="h-5" />
          <AuthInputCard>
            <div className="h-1" />
            <ErrorMessage
              className="text-card text-sm font-medium tracking-wide text-center mt-1"
              error={error}
            />
          </AuthInputCard>
        </>
      )}
    </div>
  );
};

export default AuthError;
