import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthBackground from 'src/components/pages/Auth/partials/AuthBackground';
import AuthContainerCard from 'src/components/pages/Auth/partials/AuthContainerCard';
import AuthError from 'src/components/pages/Auth/partials/AuthError';
import AuthFooter from 'src/components/pages/Auth/partials/AuthFooter';
import AuthInputCard from 'src/components/pages/Auth/partials/AuthInputCard';
import AuthLogo from 'src/components/pages/Auth/partials/AuthLogo';
import AuthSubmitButton from 'src/components/pages/Auth/partials/AuthSubmitButton';
import AuthTitle from 'src/components/pages/Auth/partials/AuthTitle';
import { authAction } from 'src/redux/auth/authReducer';
import { selectAuthIsLoading, selectEmailToConfirm } from 'src/redux/selectors';

const EmailConfirmationPage: FC = () => {
  const dispatch = useDispatch();
  const email = useSelector(selectEmailToConfirm);
  const isLoading = useSelector(selectAuthIsLoading);
  const location = useLocation();

  const isConfirmed = useMemo(() => location.search.includes('confirmed'), [location.search]);

  const resendConfirmationClick = () => {
    if (email === null) return;
    
    dispatch(authAction.emailConfirmationLink(email));
  };

  const loginClick = () => {
    dispatch(authAction.setEmailToConfirm(null));
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthLogo />
        <AuthInputCard>
          <AuthTitle>Confirm Email</AuthTitle>

          {isConfirmed ? (
            <p className="text-white/90 mx-3 mb-3 text-sm text-center">
              Your email has been confirmed. You can now log in.
            </p>
          ) : (
            <>
              <p className="text-white/90 mx-3 mb-3 text-sm text-center">
                Confirm your email adress to log in. We've sent a confirmation link to:
              </p>
              <p className="self-center bg-dark-border/50 rounded-smaller px-4 py-1 text-white mx-3 mb-2 text-center tracking-wide">
                {email}
              </p>
              <button
                onClick={resendConfirmationClick}
                className={`
                  self-center px-4 py-2 tracking-wider text-semibold
                  text-white/90 text-sm rounded-small mb-6
                  hover:bg-dark-border text-inter li border border-dark-border
                  ${isLoading ? 'bg-dark-border animate-pulse cursor-default' : 'hover:underline'}
                `} >
                {isLoading ? 'Sending...' : 'Resend Link'}
              </button>
            </>
          )}

          <AuthSubmitButton noLoading onClick={loginClick}>
            Back to Login
          </AuthSubmitButton>
        </AuthInputCard>
        <AuthError />
        <AuthFooter />
      </AuthContainerCard>
    </AuthBackground>
  );
};

export default EmailConfirmationPage;
