import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Icons } from 'src/assets';
import ErrorMessage from 'src/components/common/ErrorMessage/ErrorMessage';
import Filtering from 'src/components/pages/Search/partials/Filtering/Filtering';
import Layout from 'src/components/pages/Search/partials/Layout/Layout';
import LoadingGradient from 'src/components/pages/Search/partials/LoadingGradient/LoadingGradient';
import SearchResult from 'src/components/pages/Search/partials/SearchResult/SearchResult';
import useOnScroll from 'src/hooks/useOnScroll';
import { searchAction } from 'src/redux/search/searchReducer';
import {
  selectCurrentSearch,
  selectSearchError,
  selectSearchIsLoading,
  selectSearchIsProcessingOrPendingInitially,
  selectSearchResults,
} from 'src/redux/selectors';
import { DOCUMENT_PER_REQUEST } from 'src/services/api/apiService';
import { ApiResultPageStatus } from 'src/services/api/apiValidation';
import { ApiResultDocument, ApiResultPage } from 'src/types/api';

const ResultPage = ({ page }: { page: ApiResultPage }) => {
  const navigate = useNavigate();

  const onOpen = (document: ApiResultDocument, chunkIndex: number) => {
    navigate(
      `${document.documentId}?page=${document.resultChunks[chunkIndex]?.pageNumber}&extension=${page.index}`,
    );
  };

  if (page.status !== ApiResultPageStatus.COMPLETE) return null;

  return (
    <div className="flex flex-col gap-6 mb-6">
      {page.result?.result.documents.map((result) => (
        <SearchResult resultDocument={result} onOpen={onOpen} key={result.documentId} />
      ))}
    </div>
  );
};

const ProcessingAnimation = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (counter < 4) {
      timeoutId = setTimeout(() => setCounter((prev) => prev + 1), 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [counter]);

  const messages = [
    'Understanding query prompt and filters',
    'Searching for relevant documents',
    'Analyzing text',
    'Finding relevant result',
    'Sending results',
  ]

  return (
    <div className="flex flex-col h-full items-center text-xs gap-1  ">
      {messages.map((searchText, index) => (
        <div
          key={index}
          className={`flex gap-2 items-center transition-all  ease-in ${
            counter >= index ? ' visible ' : ' invisible '
          }`}>
          {counter === index ? (
            <div className="size-2 rounded-full animate-pulse bg-qura-primary-text" />
          ) : (
            <Icons.CheckMark className="font-bold text-qura-primary-text size-3" />
          )}
          <p className={` w-fit`}>{searchText}</p>
        </div>
      ))}
    </div>
  );
};

const SearchResultPage = () => {
  const results = useSelector(selectSearchResults);
  const searchIsLoading = useSelector(selectSearchIsLoading);
  const searchIsProcessingInitially = useSelector(selectSearchIsProcessingOrPendingInitially);
  const search = useSelector(selectCurrentSearch);
  const dispatch = useDispatch();
  const { searchId: paramSearchId } = useParams();
  const navigate = useNavigate();
  const searchError = useSelector(selectSearchError);
  const displayError = searchError ? searchError.type !== 'identical-request' && searchError.type !== 'unimportant' : false;

  useOnScroll({
    onBottom: () => {
      if (search === null) return;
      if (searchIsLoading) return;
      dispatch(searchAction.extendSearch());
    },
  });

  useEffect(() => {
    if (paramSearchId && paramSearchId !== search?.searchId) {
      dispatch(
        searchAction.loadSearch({
          searchId: paramSearchId,
          firstPageIndex: 0,
          nextPageIndex: 0,
          resultUrl: '',
        }),
      );
    }
  }, [paramSearchId]);

  useEffect(() => {
    if (search?.searchId && search.searchId !== paramSearchId) {
      navigate(`/search/${search.searchId}`);
    }
  }, [search]);

  return (
    <Layout
      contentClassName="w-[min(740px,85%)]"
      leftContent={({ overlap }) => (
        <Filtering className="pointer-events-auto" overlap={overlap < 0} />
      )}>
      {!displayError &&
        results.length > 0 &&
        results.map((page) => <ResultPage key={page.index} page={page} />)}
      {!displayError && !searchIsLoading && results.length === 0 && (
        <p className="text-xs">No search results found, try updating your filters or query</p>
      )}
      {searchIsLoading && !displayError && (
        <div className="flex flex-col gap-6">
          {Array(DOCUMENT_PER_REQUEST)
            .fill(undefined)
            .map((_, index) => (
              <LoadingGradient className="h-60 w-400" key={index} />
            ))}
        </div>
      )}
      {displayError && (
        <div className="flex flex-col gap-2 items-start h-full">
          <ErrorMessage error={searchError} className="text-xs" />
          <button
            className="bg-qura-white border border-qura-border text-qura-primary-text hover:bg-qura-btn-hover text-xs px-2 py-1.5 rounded"
            onClick={() => dispatch(searchAction.newSearch())}>
            Try again
          </button>
        </div>
      )}
    </Layout>
  );
};

export default SearchResultPage;
