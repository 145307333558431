/**
 * API Integration for LawLabs.
 * This module contains functions and types for interacting with the LawLabs API,
 * including search functionality and JWT token management.
 */

import axios, { AxiosRequestConfig } from 'axios';
import { env } from 'src/env';
import { ApiResources, ApiSearchLimits, ApiSearchParams, ApiSearchState } from 'src/types/api';
import devlog from 'src/utilities/devlog';
import {
  convertCamelToSnakeCaseRecursive,
  convertSnakeToCamelCaseObject,
} from 'src/utilities/snakeToCamelCase';

import {
  apiDocumentMetaResult,
  apiExtendSearchResponseSchema,
  apiNewSearchResponseSchema,
  apiResourcesSchema,
  apiSearchResultSchema,
} from './apiValidation';
import { standardAxiosOptions } from '../axiosOptions';

export const DOCUMENT_PER_REQUEST = 5;
export const CHUNK_COUNT_LIMIT = 5;
export const MAX_DOCUMENTS_PER_SEARCH = 50;

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api/v1'
    : env.REACT_APP_API_URL;
devlog('API URL', baseUrl);

/**
 *
 * @param query
 * @param numDocuments must be between 1 and 50
 * @param engine
 * @returns {ApiSearchResult}
 */
export const newSearchRequest = async (
  params: ApiSearchParams,
  limits: ApiSearchLimits,
): Promise<ApiSearchState> => {
  const data = convertCamelToSnakeCaseRecursive({ params, limits });

  const response = await axios.post(`${baseUrl}/search/submit`, data, standardAxiosOptions());
  const validated = apiNewSearchResponseSchema.parse(response.data);

  return {
    ...validated,
    nextPageIndex: validated.firstPageIndex,
  };
};

/**
 *
 * @param searchId search id from previuous search request made using searchRequest function
 * @param numDocuments must be between 1 and 50
 * @returns {ApiSearchResult}
 */
export const extendSearchRequest = async (
  search: ApiSearchState,
  limits: ApiSearchLimits,
): Promise<ApiSearchState> => {
  const data = convertCamelToSnakeCaseRecursive({
    currentPageIndex: search.nextPageIndex,
    limits,
  });

  try {
    const response = await axios.post(
      `${baseUrl}/search/${search.searchId}/extend`,
      data,
      standardAxiosOptions(),
    );

    const validated = apiExtendSearchResponseSchema.parse(response.data);

    return {
      ...search,
      ...validated,
      searchId: search.searchId,
    };
  } catch (error) {
    throw error;
  }
};

export async function getSearchResultsRequest(search: ApiSearchState) {
  const response = await axios.get(
    `${baseUrl}/search/${search.searchId}?offset=${search.nextPageIndex}&limit=1`,
    standardAxiosOptions(),
  );
  const validated = apiSearchResultSchema.parse(response.data);

  return convertSnakeToCamelCaseObject(validated);
}

/**
 * Gets a list of document classes and subclasses from the LawLabs API.
 * @returns {Promise<ApiResources>} A promise that resolves with an ApiResources object.
 */
export async function getResourcesRequest(): Promise<ApiResources> {
  const response = await axios.get(`${baseUrl}/database/resources`, standardAxiosOptions());
  const validated = apiResourcesSchema.parse(response.data);

  return convertSnakeToCamelCaseObject(validated);
}

const pruneBigData = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  const newObj: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === 'pdfExcerpt' || key === 'metadata') {
        continue;
      }
      newObj[key] = pruneBigData(obj[key]);
    }
  }
  return newObj;
};

/**
 * Send feedback to the LawLabs API.
 * @param message The feedback message to send.
 * @param data The data to send with the feedback message.
 * @returns {Promise<void>} A promise that resolves when the feedback is sent.
 */
export async function sendFeedbackRequest(
  message: string,
  data: Record<string, unknown>,
): Promise<void> {
  await axios.post(
    `${baseUrl}/database/log/feedback`,
    {
      message,
      data: pruneBigData(data),
    },
    standardAxiosOptions(),
  );
}

/**
 * Send feedback to the LawLabs API.
 * @param message The feedback message to send.
 * @param data The data to send with the feedback message.
 * @returns {Promise<void>} A promise that resolves when the feedback is sent.
 */
export async function sendError(message: string, data: Record<string, unknown>): Promise<void> {
  await axios.post(`${baseUrl}/database/log/error`, {
    message,
    data: pruneBigData(data),
  });
}

export async function getSearchedDocumentMetaData(searchId: string, documentId: string) {
  const response = await axios.get(
    `${baseUrl}/database/${documentId}/${searchId}/meta`,
    standardAxiosOptions(),
  );

  const validated = apiDocumentMetaResult.parse(response.data);

  return convertSnakeToCamelCaseObject(validated);
}
