import { ActionCreatorWithPayload, createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';
import { selectUser } from 'src/redux/selectors';
import { sendError } from 'src/services/api/apiService';
import { AppError } from 'src/types/appError';
import { User } from 'src/types/auth';
import devlog from 'src/utilities/devlog';
import { getBrowserId, getSessionId } from 'src/utilities/getSessionId';
import validateError from 'src/utilities/validateError';

export function* handleErrorSaga(
  failedAction: (error: AppError) => PayloadAction<AppError>,
  error: unknown,
) {
  const appError = validateError(error);

  if (appError.type !== 'unimportant' && appError.type !== 'email-not-confirmed') {
    try {
      const user: User = (yield select(selectUser)) ?? {
        id: 'unknown',
        name: 'unknown',
        email: 'unknown',
      };
      const store: Record<string, string> = yield select((s) => s);
      const localStorageData: Record<string, string | null> = {};
      const cookieData = document.cookie;

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if(!key) continue;
        localStorageData[key] = localStorage.getItem(key);
      }

      console.error(appError);

      const errorMetadata = {
        error_type: appError.type,
        browser_id: getBrowserId(),
        session_id: getSessionId(),
        name: user.name,
        email: user.email,
        user_id: user.id,
        location: window.location,
        store,
        localStorage: localStorageData,
        cookies: cookieData
      };

      yield call(sendError, appError.message, errorMetadata);
    } catch (e) {
      devlog('Failed to log error', e);
    }
  }

  yield put(failedAction(appError));
}

export function createErrorSagaHandle<In>(
  generator: (input: PayloadAction<In>) => Generator<any, any, any>,
  failedAction: (error: AppError) => PayloadAction<AppError>,
) {
  return function* sagaHandle(action: PayloadAction<In>) {
    try {
      yield generator(action);
    } catch (error) {
      yield handleErrorSaga(failedAction, error);
    }
  };
}

export function createAsyncSagaHandle<In, Out>(
  asyncFunction: (input: In) => Promise<Out>,
  successAction: (payload: Out) => PayloadAction<Out>,
  failedAction: (error: AppError) => PayloadAction<AppError>,
) {
  return function* sagaHandle(action: PayloadAction<In>) {
    try {
      const resources: Out = yield call(asyncFunction, action.payload);
      yield put(successAction(resources));
    } catch (error) {
      yield handleErrorSaga(failedAction, error);
    }
  };
}
export function createAsyncActions<In, Out>(
  name: string,
): [
    ActionCreatorWithPayload<In, string>,
    ActionCreatorWithPayload<Out, string>,
    ActionCreatorWithPayload<AppError, string>,
  ] {
  const start = createAction<In>(`${name}-start`) as ActionCreatorWithPayload<In, string>;
  const success = createAction<Out>(`${name}-success`) as ActionCreatorWithPayload<Out, string>;
  const failed = createAction<AppError>(`${name}-failed`) as ActionCreatorWithPayload<
    AppError,
    string
  >;
  return [start, success, failed];
}
