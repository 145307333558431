import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import authReducer from './auth/authReducer';
import authSaga from './auth/authSaga';
import resourceReducer from './resources/resourceReducer';
import resourcesSaga from './resources/resourceSaga';
import searchReducer from './search/searchReducer';
import { searchSaga } from './search/searchSaga';
import devlog from 'src/utilities/devlog';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create a logger middleware
const loggerMiddleware = (storeAPI: any) => (next: any) => (action: any) => {
  devlog('Dispatching action:', action);
  const result = next(action);
  devlog('Next state:', storeAPI.getState());
  return result;
};

function stateSanitizer(obj: any, sanitizeKey: string) {
  if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: any, key) => {
      if (key === sanitizeKey) {
        acc[key] = '<LARGE_OBJECT_SANITIZED>';
      } else {
        acc[key] = stateSanitizer(obj[key], sanitizeKey);
      }
      return acc;
    }, {});
  } else return obj;
}

export const reducers = {
  auth: authReducer,
  search: searchReducer,
  resources: resourceReducer,
};

const store = configureStore({
  reducer: reducers,
  devTools: {
    stateSanitizer: (state) => stateSanitizer(state, 'pdfExcerpt'),
    actionSanitizer: (action) =>
      action.type === 'search/get-results-success' ? stateSanitizer(action, 'pdfExcerpt') : action,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      sagaMiddleware,
      // loggerMiddleware
    ),
});

sagaMiddleware.run(resourcesSaga);
sagaMiddleware.run(searchSaga);
sagaMiddleware.run(authSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
