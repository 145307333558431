import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { Icons } from 'src/assets';
import { searchAction } from 'src/redux/search/searchReducer';
import {
  selectAvailableDateRange,
  selectAvailableDocumentTags,
  selectFilter,
  selectFilterDateRange,
  selectSubmittedFilters,
} from 'src/redux/selectors';
import { ApiSearchFilter, ApiTag } from 'src/types/api';

import TagButton from './TagButton';

type Props = {
  className?: string;
  overlap?: boolean;
};

const parseFilterTags = (filter: ApiSearchFilter | null | undefined) =>
  filter?.tags && filter.tags.length > 0 ? new Set(filter.tags.flatMap((tag) => tag)) : null;

const parseFilterDateRange = (filter: ApiSearchFilter | null | undefined) =>
  filter?.dateRange && filter.dateRange.length > 0 ? filter.dateRange : null;

const useFilterHelpers = () => {
  const filter = useSelector(selectFilter);
  const submittedFilter = useSelector(selectSubmittedFilters);
  const filterTags = parseFilterTags(filter);
  const submittedFilterTags = parseFilterTags(submittedFilter);
  const tagsIsEqual = isEqual(filterTags, submittedFilterTags);
  const filterDateRange = parseFilterDateRange(filter);
  const submittedFilterDateRange = parseFilterDateRange(submittedFilter);
  const dateRangeIsEqual = isEqual(filterDateRange, submittedFilterDateRange);

  const filterIsEqual = tagsIsEqual && dateRangeIsEqual;

  return {
    filterTags,
    filterDateRange,
    submittedFilter,
    submittedFilterTags,
    submittedFilterDateRange,
    filterIsEqual,
  };
};

const renderTag = (option: ApiTag, tagPath: string[] = []) => {
  return (
    <TagButton
      key={option.id}
      tag={option}
      tagPath={[...tagPath, option.id]}
      renderSubTag={(parentOption, option) => renderTag(option, [...tagPath, parentOption.id])}
    />
  );
};

const YearFilter = () => {
  const dispatch = useDispatch();
  const filterDateRange = useSelector(selectFilterDateRange);
  const availableDateRange = useSelector(selectAvailableDateRange);
  const [isActive, setIsActive] = useState(!!filterDateRange);

  useEffect(() => {
    if (!filterDateRange) setIsActive(false);
  }, [filterDateRange]);

  const [yearFromInput, setYearFromInput] = useState<string>(
    availableDateRange ? availableDateRange[0]?.[0]?.toString() ?? '' : '',
  );
  const [yearToInput, setYearToInput] = useState<string>(
    availableDateRange ? availableDateRange[1]?.[0]?.toString() ?? '' : '',
  );

  useEffect(() => {
    if (filterDateRange) {
      setYearFromInput(filterDateRange[0]?.[0]?.toString() ?? '');
      setYearToInput(filterDateRange[1]?.[0]?.toString() ?? '');
      return;
    }

    if (availableDateRange) {
      setYearFromInput(availableDateRange[0]?.[0]?.toString() ?? '');
      setYearToInput(availableDateRange[1]?.[0]?.toString() ?? '');
    }
  }, [availableDateRange, filterDateRange]);

  const applyYearInput = () => {
    if (!availableDateRange) return;

    const yearInputArray = [parseInt(yearFromInput), parseInt(yearToInput)];
    let yearFrom = Math.min(...yearInputArray);
    let yearTo = Math.max(...yearInputArray);

    if (isNaN(yearFrom) || isNaN(yearTo)) {
      const range = filterDateRange ?? availableDateRange;
      setYearFromInput(range[0]?.[0]?.toString() ?? '');
      setYearToInput(range[0]?.[1]?.toString() ?? '');
      return;
    }

    yearFrom = Math.max(yearFrom, availableDateRange[0]?.[0] ?? 0);
    yearTo = Math.min(yearTo, availableDateRange[1]?.[0] ?? 0);

    if (yearFrom !== 0 && yearTo !== 0) {
      dispatch(
        searchAction.setDateFilter([
          [yearFrom, 1, 1],
          [yearTo, 12, 31],
        ]),
      );
    }
  };

  useEffect(() => {
    if (isActive) {
      applyYearInput();
    } else if (filterDateRange) {
      dispatch(searchAction.setDateFilter(null));
    }
  }, [isActive]);

  if (!availableDateRange) return null;

  return (
    <div className="flex items-center  px-5 h-8">
      <button
        onClick={() => setIsActive((prev) => !prev)}
        className={`flex items-center p-2 gap-2 hover:bg-qura-btn-hover rounded h-8 ${
          !isActive && 'w-full'
        }`}>
        <div className=" w-2 h-2 rounded-full border border-qura-primary-text flex justify-center items-center">
          {isActive && <div className=" w-1 h-1 rounded-full bg-qura-primary-text" />}
        </div>
        {!isActive && <p>Filter by year</p>}
      </button>
      {isActive && (
        <div className="flex gap-2 items-center w-full">
          <label htmlFor="fromYear" className="text-gray-600 w-fit">
            From
          </label>
          <input
            className="text-gray-600 w-10  rounded-smaller text-center bg-qura-btn-hover border focus:text-black focus:border-slate-200 focus:bg-transparent border-qura-border outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            id="fromYear"
            value={yearFromInput}
            onBlur={applyYearInput}
            onChange={(e) => setYearFromInput(e.target.value)}
          />
          <label htmlFor="toYear" className="text-gray-600">
            to
          </label>
          <input
            className="text-gray-600 w-10  rounded-smaller text-center border bg-qura-btn-hover focus:text-black focus:border-slate-200 focus:bg-transparent border-qura-border outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            id="toYear"
            value={yearToInput}
            onBlur={applyYearInput}
            onChange={(e) => setYearToInput(e.target.value)}
          />
          <button
            onClick={() => setIsActive(false)}
            className="flex items-center p-2 gap-2 hover:bg-qura-btn-hover rounded h-8 ml-2">
            <Icons.Close className="w-2" />
          </button>
        </div>
      )}
    </div>
  );
};

const FilterDivider = ({ className }: { className?: string }) => {
  return <div className={'h-[0.5px] flex-shrink-0  bg-qura-border mx-5 ' + className} />;
};

const FilterHeader = () => {
  const dispatch = useDispatch();
  const { filterTags, filterDateRange, submittedFilter } = useFilterHelpers();

  const resetClick = () => {
    dispatch(searchAction.resetFilter());
    if (submittedFilter) dispatch(searchAction.newSearch());
  };

  return (
    <div className="flex justify-between px-5">
      <p className="font-semibold text-qura-primary-text font-large  mb-2 tracking-wide">Filter</p>
      {(filterTags || filterDateRange) && (
        <button
          className=" hover:underline text-qura-primary-text  mr-2 mb-2 tracking-wide"
          onClick={resetClick}>
          Reset
        </button>
      )}
    </div>
  );
};

const FilterBadge = () => {
  const { submittedFilterTags, submittedFilterDateRange, filterIsEqual } = useFilterHelpers();

  const submittedfilterLength =
    (submittedFilterTags?.size ?? 0) + (submittedFilterDateRange ? 1 : 0);

  if (!filterIsEqual)
    return (
      <div className="absolute -right-2 -top-2 size-5 flex items-center justify-center rounded-full text-center text-xs bg-qura-primary-text text-qura-white font-extrabold transition-all group-hover:hidden">
        !
      </div>
    );
  if (submittedfilterLength > 0)
    return (
      <div className="absolute -right-2 -top-2 size-5 flex items-center justify-center rounded-full text-center text-xs bg-qura-primary-text text-qura-white">
        {submittedfilterLength}
      </div>
    );
};

const FilterTags = () => {
  const availableTags = useSelector(selectAvailableDocumentTags);

  return (
    <div className="overflow-y-auto h-full w-full px-5 space-y-1 py-2">
      {availableTags.map((t) => renderTag(t))}
    </div>
  );
};

const ApplyButton = () => {
  const dispatch = useDispatch();
  const { filterIsEqual } = useFilterHelpers();

  const makeNewSearch = () => {
    dispatch(searchAction.newSearch());
  };

  return (
    <div className="flex flex-col mx-5">
      <button
        disabled={filterIsEqual}
        className={` border border-qura-border rounded-md mt-.5 py-1.5 text-center ${
          filterIsEqual
            ? ' bg-qura-btn-hover text-qura-ternary-text cursor-not-allowed'
            : 'bg-qura-primary-text text-qura-white hover:bg-qura-secondary-text font-medium'
        }`}
        onClick={makeNewSearch}>
        Apply selected filters
      </button>
    </div>
  );
};

const Filtering: FC<Props> = ({ className, overlap }) => {
  return (
    <div
      className={`${className}  flex w-filter flex-col rounded-md shadow-qura text-xs  pt-4 pb-4  h-full min-w-52 bg-qura-white relative group`}>
      {overlap && <FilterBadge />}
      <FilterHeader />
      <FilterDivider />
      <FilterTags />
      <FilterDivider className=" mb-2" />
      <YearFilter />
      <FilterDivider className="my-2" />
      <ApplyButton />
    </div>
  );
};

export default Filtering;
