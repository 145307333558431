import { FC, useEffect } from "react";
import posthog from 'posthog-js';
import { useSelector } from "react-redux";
import { selectUser } from "src/redux/selectors";

export const IdentifyUser: FC = () => {
    const user = useSelector(selectUser);

    useEffect(() => {
        if(user) {
            posthog.identify(user.id.toString())
        }
    }, [user])

    return null
}