import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncActions } from 'src/redux/reduxHelpers';
import {
  ApiResultDocument,
  ApiResultPage,
  ApiSearchFilter,
  ApiSearchParams,
  ApiSearchResult,
  ApiSearchState,
} from 'src/types/api';
import { AppError } from 'src/types/appError';
import devlog from 'src/utilities/devlog';

import { determineTagSelected, TAG_SEPARATOR, tagPathToString } from './searchHelpers';

type LoadingTypes = 'new-search' | 'extend-search' | 'get-results' | 'load-search';

type State = {
  search: ApiSearchState | null;
  parameters: ApiSearchParams;

  loadedParamsForSearchId: string | null;
  submittedParams: ApiSearchParams | null;

  resultPages: ApiResultPage[];
  documentCache: Record<string, ApiResultDocument>;
  hasStartedSearch: boolean;
  areLoading: LoadingTypes[];
  error: AppError | null;
};

const initalState: State = {
  search: null,
  loadedParamsForSearchId: null,
  parameters: {
    query: '',
    filter: null,
    searchModes: null,
  },
  submittedParams: null,
  resultPages: [],
  documentCache: {},
  hasStartedSearch: false,
  areLoading: [],
  error: null,
};

function documentDictFromPages(pages: ApiResultPage[]): Record<string, ApiResultDocument> {
  const dict: Record<string, ApiResultDocument> = {};

  pages.forEach((page) => {
    page.result?.result.documents.forEach((document) => {
      dict[document.documentId] = document;
    });
  });

  return dict;
}

export const searchSlice = createSlice({
  name: 'search',
  initialState: initalState,
  reducers: {
    addTagCombinationToFilter: (state, { payload }: PayloadAction<{ tagPath: string[] }>) => {
      devlog('addTagCombinationToFilter', payload);
      const oldFilter = state.parameters.filter ?? ({} as ApiSearchFilter);
      const tagSelection = oldFilter.tags ?? [];
      const isSelected = determineTagSelected(payload.tagPath, tagSelection);
      const toSelectTagPath = tagPathToString(payload.tagPath);

      if (!isSelected) {
        state.parameters.filter = {
          ...oldFilter,
          tags: [
            ...tagSelection.filter(
              (selectedPath) => !toSelectTagPath.startsWith(tagPathToString(selectedPath)),
            ),
            payload.tagPath,
          ],
        };
      }
      devlog('state.parameters.filter', state.parameters.filter);
    },
    removeTagCombinationToFilter: (state, { payload }: PayloadAction<{ tag: string }>) => {
      devlog('removeTagCombinationToFilter', payload);
      const oldFilter = state.parameters.filter ?? ({} as ApiSearchFilter);
      const tagSelection = oldFilter.tags ?? [];

      state.parameters.filter = {
        ...oldFilter,
        tags: tagSelection
          .map((selectedPath) => {
            const tagPath =
              tagPathToString(selectedPath).split(tagPathToString([payload.tag]))[0] ?? '';

            return tagPath.split(TAG_SEPARATOR).filter(Boolean);
          })
          .filter((current) => current.length > 0),
      };
      devlog('state.parameters.filter', state.parameters.filter);
    },
    setDateFilter: (state, { payload }: PayloadAction<ApiSearchFilter['dateRange']>) => {
      const oldFilter = state.parameters.filter ?? ({} as ApiSearchFilter);

      state.parameters.filter = {
        ...oldFilter,
        dateRange: payload,
      };
    },
    setQuery: (state, { payload: { query } }: PayloadAction<{ query: string }>) => {
      state.parameters.query = query;
    },
    loadSearch: (state, { payload }: PayloadAction<ApiSearchState>) => {
      state.hasStartedSearch = true;
      state.areLoading.push('load-search');
      state.search = payload;
    },
    setDocumentFilter: (state, { payload }: PayloadAction<string[]>) => {
      const oldFilter = state.parameters.filter ?? ({} as ApiSearchFilter);

      state.parameters.filter = {
        ...oldFilter,
        documentIds: [...payload],
      };
    },
    resetResultPages: (state) => {
      state.resultPages = [];
    },
    resetFilter: (state) => {
      state.parameters.filter = null;
    },
    clear: () => ({
      ...initalState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(newSearch, (state) => {
        state.areLoading = [...state.areLoading, 'new-search'];
        state.hasStartedSearch = true;
      })
      .addCase(newSearchSuccess, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'new-search');
        state.error = null;
        state.search = payload;
        state.resultPages = [];
        state.loadedParamsForSearchId = payload.searchId;
        state.submittedParams = state.parameters;
      })
      .addCase(newSearchFailed, (state, action) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'new-search');
        state.error = action.payload;
      })
      .addCase(extendSearch, (state) => {
        state.areLoading = [...state.areLoading, 'extend-search'];
      })
      .addCase(extendSearchSuccess, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'extend-search');
        state.error = null;
        state.search = payload;
        state.resultPages = state.resultPages.slice(0, payload.nextPageIndex);
        state.loadedParamsForSearchId = payload.searchId;
      })
      .addCase(extendSearchFailed, (state, action) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'extend-search');
        state.error = action.payload;
      })
      .addCase(getResults, (state) => {
        state.areLoading = [...state.areLoading, 'get-results'];
      })
      .addCase(getResultsSuccess, (state, { payload }) => {
        if (!state.search) return;

        if (state.loadedParamsForSearchId !== state.search.searchId) {
          state.parameters = payload.params;
          state.submittedParams = payload.params;
          state.resultPages = [];
          state.documentCache = {};
          state.loadedParamsForSearchId = state.search.searchId;
        }

        const status = [];
        for (const page of payload.pages) {
          state.resultPages[page.index] = page;
          status.push(page.status);
        }

        state.documentCache = {
          ...state.documentCache,
          ...documentDictFromPages(payload.pages),
        };
        state.areLoading = state.areLoading.filter(
          (type) => type !== 'get-results' && type !== 'load-search',
        );
      })
      .addCase(getResultsFailed, (state, action) => {
        state.areLoading = state.areLoading.filter(
          (type) => type !== 'get-results' && type !== 'load-search',
        );
        state.error = action.payload;
        state.submittedParams = null;
      });
  },
});

const [newSearch, newSearchSuccess, newSearchFailed] = createAsyncActions<void, ApiSearchState>(
  'search/new-search',
);

const [extendSearch, extendSearchSuccess, extendSearchFailed] = createAsyncActions<
  void,
  ApiSearchState
>('search/extend-search');

const [getResults, getResultsSuccess, getResultsFailed] = createAsyncActions<void, ApiSearchResult>(
  'search/get-results',
);

const newSearchDebounce = createAction<void>('search/new-search-debounce');

export const searchAction = {
  ...searchSlice.actions,
  extendSearch,
  newSearch,
  extendSearchSuccess,
  extendSearchFailed,
  newSearchSuccess,
  newSearchFailed,
  getResults,
  getResultsSuccess,
  getResultsFailed,
  newSearchDebounce,
};

export default searchSlice.reducer;
