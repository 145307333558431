import React, { ChangeEvent, FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Icons } from 'src/assets';
import Tag from 'src/components/pages/Search/partials/Tag/Tag';

type Props = {
  value: string;
  setValue: (search: string) => void;
  onSearch: () => void;
  disabled?: boolean;
};

const LINE_HEIGHT = 23;
const MAX_HEIGHT = 23 * 6;

const SearchBar: FC<Props> = ({ value, setValue, onSearch, disabled }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { documentId } = useParams();

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    updateTextareaHeight();
  };

  useEffect(() => {
    updateTextareaHeight();
  }, [value]);

  const updateTextareaHeight = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSearch();
    }
  };

  return (
    <>
      <div className="flex items-center py-4 min-h-14 w-full rounded-[1.75rem]  text-qura-primary-text bg-qura-white gap-4 px-6 text-sm shadow-qura">
        <button onClick={onSearch} title="Search">
          <Icons.NewSearch className="h-4" />
        </button>

        <textarea
          disabled={disabled}
          style={{ lineHeight: `${LINE_HEIGHT}px` }}
          ref={textareaRef}
          className="flex-1 resize-none bg-transparent outline-none border-none"
          value={value}
          onChange={onChangeTextarea}
          onKeyDown={handleKeyDown}
          placeholder="Search Qura AI..."
        />

        {documentId && <Tag className="font-medium">Searching in document</Tag>}
      </div>
    </>
  );
};

export default SearchBar;
