import { all, put, takeLatest } from 'redux-saga/effects';
import { authAction } from 'src/redux/auth/authReducer';
import { createAsyncSagaHandle } from 'src/redux/reduxHelpers';
import { getResourcesRequest } from 'src/services/api/apiService';

import { resourceAction } from './resourceReducer';

const loadResourcesHandle = createAsyncSagaHandle(
  getResourcesRequest,
  resourceAction.loadResourcesSuccess,
  resourceAction.loadResourcesFailed,
);

function* handleAuthenticated() {
  yield put(resourceAction.loadResources());
}

function* handleResourcesFailed() {
  yield put(
    authAction.loginFailed({ type: 'load-resources-failed', message: 'Failed to load resources' }),
  );
}

function* handleLogout() {
  yield put(resourceAction.clear());
}

function* resourcesSaga() {
  yield all([
    takeLatest(authAction.logoutSuccess, handleLogout),
    takeLatest(resourceAction.loadResources, loadResourcesHandle),
    takeLatest(resourceAction.loadResourcesFailed, handleResourcesFailed),
    takeLatest(authAction.loginSuccess, handleAuthenticated),
    takeLatest(authAction.refreshSuccess, handleAuthenticated),
  ]);
}

export default resourcesSaga;
