import { z } from 'zod';

// This file is used to define the environment variables that are used in the application.

const envSchema = z.object({
  REACT_APP_API_URL: z.string().default(''),
  REACT_APP_STRAPI_URL: z.string().default(''),
  REACT_APP_PUBLIC_POSTHOG_HOST: z.string().default(''),
  REACT_APP_PUBLIC_POSTHOG_KEY: z.string().default(''),
});

// In production the environment variables are passed in as docker environment variables
const dockerEnv = {
  REACT_APP_API_URL: '*REACT_APP_API_URL*',
  REACT_APP_STRAPI_URL: '*REACT_APP_STRAPI_URL*',
  REACT_APP_PUBLIC_POSTHOG_HOST: '*REACT_APP_PUBLIC_POSTHOG_HOST*',
  REACT_APP_PUBLIC_POSTHOG_KEY: '*REACT_APP_PUBLIC_POSTHOG_KEY*',
};

const isProduction = process.env.NODE_ENV === 'production';
export const env = envSchema.parse(isProduction ? dockerEnv : process.env);
