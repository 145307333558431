import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthIsLoading } from 'src/redux/selectors';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  noLoading?: boolean;
  isLoading?: boolean;
}

const AuthSubmitButton: FC<Props> = (props) => {
  const isLoading = useSelector(selectAuthIsLoading);
  const showLoadng = (isLoading || props.isLoading) && !props.noLoading;

  const buttonProps = { ...props };
  delete buttonProps.noLoading;
  delete buttonProps.isLoading;

  return (
    <button
      type="submit"
      className={`${showLoadng ? 'animate-pulse' : ''} font-inter dark-border text-small tracking-wide antialiased bg-dark-button text-white font-semibold py-3 rounded-smaller transition-all hover:shadow-none hover:border-dark-button border-white border border-opacity-10`}
      {...props}
      title={showLoadng ? 'Loading...' : props.title}
      children={showLoadng ? 'Loading...' : props.children}
    />
  );
};

export default AuthSubmitButton;
