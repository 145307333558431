import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncActions } from 'src/redux/reduxHelpers';
import { AppError } from 'src/types/appError';
import { LoginCredentials, RegisterCredentials, User, UserNotFound } from 'src/types/auth';

type LoadingTypes = 'login' | 'refresh' | 'logout' | 'register' | 'send-confirmation-link';

type State = {
  user: User | UserNotFound | null;
  areLoading: LoadingTypes[];
  error: AppError | null;
  input: {
    name: string;
    email: string;
  };
  emailToConfirm: string | null;
  hasRefreshed: boolean;
};

const initalState: State = {
  user: null,
  hasRefreshed: false,
  areLoading: [],
  error: null,
  input: {
    name: '',
    email: '',
  },
  emailToConfirm: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initalState,
  reducers: {
    clear: (state) => {
      state.user = null;
      state.areLoading = [];
      state.error = null;
      state.emailToConfirm = null;
    },
    setEmailToConfirm: (state, { payload }: PayloadAction<string | null>) => {
      state.emailToConfirm = payload;
    },
    setError: (state, { payload }: PayloadAction<AppError | null>) => {
      state.error = payload;
    },
    setEmailInput: (state, { payload }: PayloadAction<string>) => {
      state.input.email = payload;
    },
    setNameInput: (state, { payload }: PayloadAction<string>) => {
      state.input.name = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login, (state) => {
        state.areLoading.push('login');
        state.error = null;
      })
      .addCase(loginSuccess, (state, { payload }) => {
        state.user = payload;
        state.areLoading = state.areLoading.filter((type) => type !== 'login');
      })
      .addCase(loginFailed, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'login');
        state.user = 'USER_NOT_FOUND';
        state.error = payload;
      })
      .addCase(refresh, (state) => {
        state.areLoading.push('refresh');
        state.error = null;
      })
      .addCase(refreshSuccess, (state, { payload }) => {
        state.user = payload;
        state.areLoading = state.areLoading.filter((type) => type !== 'refresh');
        state.hasRefreshed = true;
      })
      .addCase(refreshFailed, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'refresh');
        state.user = 'USER_NOT_FOUND';
        state.hasRefreshed = true;
      })
      .addCase(logout, (state) => {
        state.areLoading.push('logout');
        state.error = null;
      })
      .addCase(logoutSuccess, (state) => {
        state.user = 'USER_NOT_FOUND';
        state.areLoading = state.areLoading.filter((type) => type !== 'logout');
        state.error = null;
      })
      .addCase(logoutFailed, (state, { payload }) => {
        state.error = payload;
        state.user = 'USER_NOT_FOUND';
        state.areLoading = state.areLoading.filter((type) => type !== 'logout');
      })
      .addCase(register, (state) => {
        state.areLoading.push('register');
        state.error = null;
      })
      .addCase(registerSuccess, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'register');
      })
      .addCase(registerFailed, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'register');
        state.error = payload;
      })
      .addCase(emailConfirmationLink, (state, { payload }) => {
        state.areLoading.push('send-confirmation-link');
      })
      .addCase(emailConfirmationLinkSuccess, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'send-confirmation-link');
        state.error = null;
        state.emailToConfirm = payload;
      })
      .addCase(emailConfirmationLinkFailed, (state, { payload }) => {
        state.areLoading = state.areLoading.filter((type) => type !== 'send-confirmation-link');
        state.error = payload;
      });
  },
});

const [login, loginSuccess, loginFailed] = createAsyncActions<LoginCredentials, User>('auth/login');

const [refresh, refreshSuccess, refreshFailed] = createAsyncActions<void, User>('auth/refresh');

const [logout, logoutSuccess, logoutFailed] = createAsyncActions<void, void>('auth/logout');

const [register, registerSuccess, registerFailed] = createAsyncActions<
  RegisterCredentials,
  LoginCredentials
>('auth/register');

const [emailConfirmationLink, emailConfirmationLinkSuccess, emailConfirmationLinkFailed] =
  createAsyncActions<string, string>('auth/send-confirmation-link');

export const authAction = {
  ...authSlice.actions,
  login,
  refresh,
  logout,
  loginSuccess,
  loginFailed,
  refreshSuccess,
  refreshFailed,
  logoutSuccess,
  logoutFailed,
  register,
  registerSuccess,
  registerFailed,
  emailConfirmationLink,
  emailConfirmationLinkSuccess,
  emailConfirmationLinkFailed,
};

export default authSlice.reducer;
